import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RefInterface} from '../../../../core/model/ref.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubscriptionService} from '../../subscription.service';
import {LanguageService} from '../../../../shared/services/language.service';
import * as frGenderData from '../../../../shared/data/fr-constants/genders.json';
import * as frCountryListData from '../../../../shared/data/fr-constants/countries.json';
import * as enGenderData from '../../../../shared/data/en-constants/genders.json';
import * as enCountryListData from '../../../../shared/data/en-constants/countries.json';
import {FrenchDateAdapter} from '../../../../core/adapters/french-date.adapter';
import {DateValidator} from '../../../../core/validators/date.validator';
import {CountryInterface} from '../../../../core/model/country.interface';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {CompanyApplicantService} from '../../../../shared/services/company-applicant.service';
import {DriverService} from '../../../../shared/services/driver.service';

@Component({
  selector: 'step-owner-infos',
  templateUrl: './step-owner-infos.component.html',
  styleUrls: ['./step-owner-infos.component.css']
})
export class StepOwnerInfosComponent implements OnInit {

  get genderList(): RefInterface[] {
    return this.lGenderList;
  }

  @Input() parentForm: FormGroup;
  @Input() devMode: boolean;
  @Input() mode: string;

  private lGenderList: RefInterface[];
  private countryList: CountryInterface[];

  personalInfosForm: FormGroup;
  isStateRequired = false;

  today = new Date();

  isOrganisationRequired = false;

  phonePattern = '^[0-9]{3,15}$|\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|' +
    '6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{1,14}$';

  constructor(private formBuilder: FormBuilder,
              private subscriptionService: SubscriptionService,
              private dateService: FrenchDateAdapter,
              private driverService: DriverService,
              private companyApplicantService: CompanyApplicantService,
              private authenticationService: AuthenticationService,
              private languageService: LanguageService,
              private cdRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.personalInfosForm = this.formBuilder.group({
      applicant: false,
      lastName: [null, [Validators.required, Validators.maxLength(100)]],
      firstName: [null, [Validators.required, Validators.maxLength(100)]],
      gender: [null, Validators.required],
      birthDate: [null, [Validators.required, DateValidator.adult]],
      birthCountry: [null, Validators.required],
      birthCity: [null, Validators.required],
      email: [null, Validators.required],
      nationality: [null, Validators.required],
      phone: [null, [Validators.required, Validators.maxLength(15), Validators.pattern(this.phonePattern)]]
    });

    this.authenticationService.currentUser.subscribe(user => {
      if (user) {
        this.personalInfosForm.get('email').setValue(user.username);
        this.personalInfosForm.get('email').disable();
      }
    });

    this.languageService.getCurrentLanguage().subscribe(lang => {
      const selectedGender = this.personalInfosForm.get('gender').value;
      switch (lang) {
        case 'fr':
          this.lGenderList = (frGenderData as any).default;
          this.countryList = (frCountryListData as any).default;
          break;
        case 'en':
          this.lGenderList = (enGenderData as any).default;
          this.countryList = (enCountryListData as any).default;
          break;
      }
      if (selectedGender) {
        this.personalInfosForm.get('gender').setValue(this.lGenderList.filter(item => item.code === selectedGender.code)[0]);
      }
    });

    this.subscriptionService.getModification().subscribe(modification => {
      if (this.mode === 'UPDATE' && modification != null && modification.applicant != null) {
        if (!modification.applicant.company) {
          this.personalInfosForm.get('lastName').setValue(modification.applicant.lastName);
          this.personalInfosForm.get('firstName').setValue(modification.applicant.firstName);
          this.personalInfosForm.get('gender').setValue(this.lGenderList.filter(item => item.code === modification.applicant.gender)[0]);
          this.personalInfosForm.get('birthDate').setValue(this.dateService.parse(modification.applicant.birthInfo.day));
          this.personalInfosForm.get('birthCountry').setValue(
            this.countryList.filter(item => item.code === modification.applicant.birthInfo.country)[0]);
          this.personalInfosForm.get('birthCity').setValue(modification.applicant.birthInfo.city);
          this.personalInfosForm.get('email').setValue(modification.applicant.contact.email);
          this.personalInfosForm.get('nationality').setValue(
            this.countryList.filter(item => item.code === modification.applicant.nationality)[0]);
          this.personalInfosForm.get('phone').setValue(modification.applicant.contact.mobile);
          this.personalInfosForm.get('applicant').disable();
        } else {
          this.personalInfosForm.get('applicant').setValue(true);
          this.personalInfosForm.get('applicant').disable();
          this.personalInfosForm.get('phone').setValue(modification.applicant.contact.mobile);
          this.resetPersonal();
        }
      }
    });

    this.personalInfosForm.get('applicant').valueChanges.subscribe((newValue: any) => {
      switch (newValue) {
        case false:
          // this.isParentalAuthorityRequired = false;
          this.isOrganisationRequired = false;
          this.parentForm.removeControl('organisationInfos');
          this.personalInfosForm.addControl('lastName', this.formBuilder.control(null, [Validators.required, Validators.maxLength(100)]));
          this.personalInfosForm.addControl('firstName', this.formBuilder.control(null, [Validators.required, Validators.maxLength(100)]));
          this.personalInfosForm.addControl('gender', this.formBuilder.control(null, Validators.required));
          this.personalInfosForm.addControl('birthDate', this.formBuilder.control(null, [Validators.required, DateValidator.adult]));
          this.personalInfosForm.addControl('birthCountry', this.formBuilder.control(null, Validators.required));
          this.personalInfosForm.addControl('birthCity', this.formBuilder.control(null, Validators.required));
          this.personalInfosForm.addControl('nationality', this.formBuilder.control(null, Validators.required));
          this.driverService.requireManageOwnerDriver(true);
          this.companyApplicantService.requireCompanyVouchers(false);
          this.personalInfosForm.updateValueAndValidity();
          break;
        case true:
          this.driverService.requireManageOwnerDriver(false);
          this.resetPersonal();
          break;
        default:
          break;
      }
    });

    this.parentForm.setControl('personalInfos', this.personalInfosForm);
  }

  resetPersonal() {
    this.isOrganisationRequired = true;
    this.personalInfosForm.removeControl('lastName');
    this.personalInfosForm.removeControl('firstName');
    this.personalInfosForm.removeControl('gender');
    this.personalInfosForm.removeControl('birthDate');
    this.personalInfosForm.removeControl('birthCountry');
    this.personalInfosForm.removeControl('birthCity');
    this.personalInfosForm.removeControl('nationality');
    // this.driverService.requireManageOwnerDriver(false);
    this.companyApplicantService.requireCompanyVouchers(true);
  }

  toUpperCase(key: string) {
    const value = this.personalInfosForm.get(key).value;
    this.personalInfosForm.get(key).setValue(value.toUpperCase());
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

}
