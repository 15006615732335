<div class="container" style="margin-top: 2%;padding-left: 15px;padding-right: 15px;">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <div style="vertical-align: middle">
          <mat-icon>photo_filter</mat-icon>&nbsp; <strong>{{'login.title'|translate}}</strong>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="loginForm">
        <div class="col col-md-12">
          <em  style="font-size: 0.8rem;text-align: center" *ngIf="mailValidated == false">{{'login.validationDescription'|translate}}
          </em>
          <em  style="font-size: 0.8rem;text-align: center" *ngIf="mailValidated == true">{{'login.enterValidation'|translate}}
          </em>
          <div class="row">
            <mat-form-field class="col col-md-4">
              <input matInput placeholder="{{'forms.infos.email'|translate}}" formControlName="loginMail"
                     maxlength="100" required>
              <mat-error *ngIf="loginForm.get('loginMail').errors && loginForm.get('loginMail').errors.required"
                         [translate]="'forms.mandatory'"></mat-error>
              <mat-error *ngIf="loginForm.get('loginMail').errors && loginForm.get('loginMail').errors.email">
                {{'login.wrongFormat'|translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="mailValidated" class="col col-md-4">
              <input matInput placeholder="{{'forms.infos.verificationCode'|translate}}" formControlName="code" required>
              <mat-error *ngIf="loginForm.get('code').errors && loginForm.get('code').errors.required"
                         [translate]="'forms.mandatory'"></mat-error>
            </mat-form-field>
          </div>
          <div class="row col-md-4" *ngIf="mailValidated == false">
            <ngx-recaptcha2 #captchaElem
                            [siteKey]="siteKey"
                            (reset)="handleReset()"
                            (expire)="handleExpire()"
                            (load)="handleLoad()"
                            [hl]="language"
                            (success)="handleSuccess($event)"
                            [useGlobalDomain]="true"
                            formControlName="captcha">
            </ngx-recaptcha2>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <div class="first-stepping col-md-12">
    <button class="mat-raised-button back-button width" *ngIf="!mailValidated"
            (click)="goHome()" mat-button>
      {{'common.previous'|translate}}
    </button>
    <button [disabled]="loginForm.invalid" class="mat-raised-button submit-button width"
            *ngIf="!mailValidated"
            (click)="validate()" mat-button>
      {{'common.next'|translate}}
    </button>
    <div class="validate-stepping" *ngIf="mailValidated"> &nbsp;
      <button [disabled]="loginForm.invalid" class="mat-raised-button submit-button width"
              (click)="validate()" mat-button>
        {{'common.next'|translate}}
      </button>
    </div>
  </div>
</div>
