import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Router} from "@angular/router";

@Component({
  selector: 'dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.css']
})
export class DialogConfirmComponent implements OnInit {

  message: string;
  header: string;
  backToHome: boolean = false;

  constructor(public router: Router,
              public dialogRef: MatDialogRef<DialogConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.message = data.message;
    this.header = data.header ? data.header : null;
  }

  ngOnInit() {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  goHome() {
    this.router.navigate(['']);
  }
}
