import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {LanguageService} from "../services/language.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  form: FormGroup;

  constructor(private translate: TranslateService,
              private router: Router,
              private languageService: LanguageService) {
  }

  ngOnInit() {
  }

  useLanguage(language: string) {
    localStorage.setItem('locale', language);
    this.languageService.setLanguage(language);
    this.translate.use(language);
  }

  navigate(path) {
    this.router.navigate([path]);
  }
}
