<div id="page-container" style="width: 100% !important;min-width: 100% !important;">
  <div id="content-wrap">
    <app-header></app-header>
    <router-outlet (activate)="onActivate($event)"></router-outlet>

  </div>
  <footer id="footer">
    <app-footer></app-footer>
  </footer>
</div>
