<form [formGroup]="form">
  <mat-horizontal-stepper class="container-stepper bg-transparent" [linear]="true" #stepper>
    <mat-step [completed]="false" [stepControl]="form.get('personalInfos')">
      <ng-template matStepLabel>{{'forms.steps.personal' |translate}}</ng-template>
      <step-owner-infos [parentForm]="form" [mode]="mode" [devMode]="devMode"></step-owner-infos>

      <div class="first-stepping container">
        <button class="mat-raised-button submit-button width" (click)="goForward(1)">{{'common.next'|translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [completed]="false" [stepControl]="form.get('driverInfos')">
      <ng-template matStepLabel>{{'forms.steps.driver' |translate}}</ng-template>
      <step-driver-infos [parentForm]="form" [mode]="mode" [devMode]="devMode"></step-driver-infos>
      <div class="stepping container">
        <button class="mat-raised-button back-button width" (click)="goBack()">{{'common.previous'|translate}}</button>
        <button class="mat-raised-button submit-button width" (click)="goForward(2)">{{'common.next'|translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [completed]="false" [stepControl]="form.get('vehicleInfos')">
      <ng-template matStepLabel>{{'forms.steps.stay' |translate}}</ng-template>
      <step-vehicle-infos [parentForm]="form" [mode]="mode" [devMode]="devMode"></step-vehicle-infos>
      <div class="stepping container">
        <button class="mat-raised-button back-button width" (click)="goBack()">{{'common.previous'|translate}}</button>
        <button class="mat-raised-button submit-button width" (click)="goForward(3)">{{'common.next'|translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [completed]="false" [stepControl]="form.get('vouchersInfos')">
      <ng-template matStepLabel>{{'forms.steps.vouchers' |translate}}</ng-template>
      <step-vouchers [parentForm]="form" [devMode]="devMode"></step-vouchers>
      <div class="stepping container">
        <button class="mat-raised-button back-button width" (click)="goBack()">{{'common.previous'|translate}}</button>
        <button class="mat-raised-button submit-button width" (click)="goForward(4)">{{'common.next'|translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [completed]="false">
      <ng-template matStepLabel>{{'forms.steps.summary' |translate}}</ng-template>
      <step-recap [mode]="mode"></step-recap>
      <div class="stepping container" style="width: 100%">
        <button class="mat-raised-button back-button width" style="margin-left: 1%" (click)="goBack()">{{'common.previous'|translate}}</button>
        <button class="mat-raised-button submit-button width" style="margin-right: 1%" (click)="goForward(5)">{{'common.next'|translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [completed]="false" [stepControl]="form.get('paymentInfos')">
      <ng-template matStepLabel>{{'forms.steps.payment' |translate}}</ng-template>
      <step-payment [parentForm]="form" [devMode]="devMode"></step-payment>
      <div class="stepping container">
        <div class="container stepping">
          <button class="mat-raised-button back-button width" (click)="goBack()">
            {{'common.previous'|translate}}
          </button>
          <button class="mat-raised-button submit-button width" (click)="goForward(6)">{{'common.validate'|translate}}</button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</form>
