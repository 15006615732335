import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Resource} from '../../core/model/resource.interface';
import {Vehicles} from '../../core/model/vehicles.interface';
import {Address} from '../../core/model/address.interface';
import {Applicant} from '../../core/model/applicant.interface';
import {Driver} from '../../core/model/driver.interface';
import {BirthInfo} from '../../core/model/birth-info.interface';
import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {DatePipe} from '@angular/common';
import {AuthenticationService} from '../../core/services/authentication.service';
import {Subscription} from '../../core/model/subscription.interface';
import {Vehicule} from '../../core/model/vehicule.interface';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private datePipe: DatePipe,
              private authenticationService: AuthenticationService) {
  }

  private subscritpionForm = new BehaviorSubject(null);

  private modification = new BehaviorSubject(null);

  mapAddress(form: FormGroup): Address {
    const address = form.value;
    return {
      city: address.town,
      state: address.state ? address.state : null,
      country: address.country.code,
      streetName: address.streetName,
      additional: address.additional ? address.additional : null
    };
  }

  setModification(modification: Subscription) {
    this.modification.next(modification);
  }

  getModification(): Observable<Subscription> {
    return this.modification.asObservable();
  }

  mapModification(data: Subscription): Subscription {
    const modification = {
      // status: data.status,
      process: data.process,
      applicant: data.applicant,
      payment: data.payment,
      vehicles: data.vehicles
    };
    return modification;
  }

  mapVehicle(vehicleInfos: FormGroup): Vehicule {
    return {
      brand: vehicleInfos.get('brand').value,
      category: vehicleInfos.get('category').value.code,
      number: vehicleInfos.get('serialNumber').value,
      registration: vehicleInfos.get('registration').value
    };
  }

  mapResources(voucherForm: FormGroup): Resource[] {
    const resources = [];
    resources.push(voucherForm.get('vehicleCertificate').value);
    resources.push(voucherForm.get('vehiclePass').value);
    return resources;
  }

  mapDriver(driverInfos: FormGroup, isOwnerDriver: boolean, voucherForm: FormGroup): Driver {
    const birthInfo: BirthInfo = {
      day: this.datePipe.transform(driverInfos.get('birthDate').value, 'dd/MM/yyyy'),
      city: driverInfos.get('birthCity').value,
      country: driverInfos.get('birthCountry').value.code
    };
    const data: Driver = {
      gender: driverInfos.get('gender').value.code,
      lastName: driverInfos.get('lastName').value,
      firstName: driverInfos.get('firstName').value,
      nationality: driverInfos.get('nationality').value.code,
      birthInfo
    };
    if (voucherForm != null) {
      const resources = [];
      if (isOwnerDriver) {
        resources.push(voucherForm.get('ownerIdCard').value);
      } else {
        resources.push(voucherForm.get('driverIdCard').value);
        resources.push(voucherForm.get('photoDriver').value);
      }
      data.resources = resources;
    }
    return data;
  }

  setSubscription(subscription: FormGroup) {
    this.subscritpionForm.next(subscription);
  }

  getSubscription(): Observable<FormGroup> {
    return this.subscritpionForm.asObservable();
  }

  private mapBirthInfo(personalInfos: FormGroup): BirthInfo {
    return {
      day: this.datePipe.transform(personalInfos.get('birthDate').value, 'dd/MM/yyyy'),
      city: personalInfos.get('birthCity').value,
      // state: personalInfos.get('state').value,
      country: personalInfos.get('birthCountry').value.code
    };
  }

  mapApplicant(personalInfos: FormGroup, organisationInfos: FormGroup, vouchersInfos: FormGroup): Applicant {
    let data: Applicant;
    if (personalInfos.get('applicant').value) {
      organisationInfos.get('address').setValue(personalInfos.get('address').value);
      data = {
        gender: organisationInfos.get('gender').value.code,
        lastName: organisationInfos.get('lastName').value,
        firstName: organisationInfos.get('firstName').value,
        jobTitle: organisationInfos.get('jobTitle').value,
        contact: {
          mobile: personalInfos.get('phone').value,
          email: personalInfos.get('email').value
        },
        address: null,
        nationality: null,
        birthInfo: {
          day: this.datePipe.transform(organisationInfos.get('birthDate').value, 'dd/MM/yyyy'),
          city: organisationInfos.get('birthCity').value,
          // state: personalInfos.get('state').value,
          country: organisationInfos.get('birthCountry').value.code
        },
        company: {
          name: organisationInfos.get('name').value,
          phone: organisationInfos.get('phone').value,
          email: organisationInfos.get('email').value,
          type: organisationInfos.get('type').value.code,
          siren: organisationInfos.get('siren').value,
          address: this.mapAddress(organisationInfos.get('address') as FormGroup)
        }
      };
      if (vouchersInfos != null) {
        const resources = [];
        resources.push(vouchersInfos.get('kbis').value);
        data.resources = resources;
      }
    } else {
      data = {
        gender: personalInfos.get('gender').value.code,
        lastName: personalInfos.get('lastName').value,
        firstName: personalInfos.get('firstName').value,
        contact: {
          mobile: personalInfos.get('phone').value,
          email: personalInfos.get('email').value
        },
        address: this.mapAddress(personalInfos.get('address') as FormGroup),
        nationality: personalInfos.get('nationality').value.code,
        birthInfo: this.mapBirthInfo(personalInfos)
      };
      if (vouchersInfos != null) {
        const resources = [];
        resources.push(vouchersInfos.get('photo').value);
        resources.push(vouchersInfos.get('ownerIdCard').value);
        data.resources = resources;
      }
    }
    return data;
  }
}
