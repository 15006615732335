import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {HomeComponent} from './components/home/home.component';
import {CoreModule} from './core/core.module';
import {SubscriptionComponent} from './components/subscription/subscription.component';
import {LoginComponent} from './components/login/login.component';
import {SubscriptionModule} from './components/subscription/subscription.module';
import {DialogConfirmComponent} from './shared/dialog-confirm/dialog-confirm.component';
import {AuthenticationService} from './core/services/authentication.service';
import {AuthGuard} from './core/guards/auth.guard';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BsDropdownModule} from 'ngx-bootstrap';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatIconModule} from '@angular/material';
import {ConsultationComponent} from './components/consultation/consultation.component';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {ModificationComponent} from './components/modification/modification.component';
import {LanguageService} from './shared/services/language.service';
import {RedirectService} from './shared/services/redirect.service';
import {SpinnerOverlayComponent} from './shared/spinner-overlay/spinner-overlay.component';
import {ContactComponent} from './components/contact/contact.component';
import {ResendCodeComponent} from './components/modification/resend-code/resend-code.component';
import {ErrorIntercept} from './core/interceptors/error.interceptor';
import {MatGridListModule} from "@angular/material/grid-list";
import { DialogModificationComponent } from './components/modification/dialogs/dialog-modification/dialog-modification.component';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SubscriptionComponent,
    LoginComponent,
    ModificationComponent,
    ContactComponent,
    ConsultationComponent,
    ResendCodeComponent,
    DialogModificationComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    SubscriptionModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BsDropdownModule.forRoot(),
    MatGridListModule,
    MatTooltipModule
  ],
  providers: [AuthGuard, AuthenticationService, LanguageService, RedirectService,
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true
    }],
  entryComponents: [ResendCodeComponent, DialogConfirmComponent, SpinnerOverlayComponent, DialogModificationComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


