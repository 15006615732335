import {Component, isDevMode, OnInit, ViewChild} from '@angular/core';
import {CountryInterface} from '../../core/model/country.interface';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from '../../core/model/subscription.interface';
import {PassService} from '../../shared/services/pass.service';
import {Auth} from '../../core/model/auth.interface';
import {SpinnerOverlayService} from '../../shared/services/spinner-overlay.service';
import {ModificationService} from './modification.service';
import {MatDialog, MatHorizontalStepper} from '@angular/material';
import {DatePipe} from '@angular/common';
import {DialogConfirmComponent} from '../../shared/dialog-confirm/dialog-confirm.component';
import {ResendCodeComponent} from './resend-code/resend-code.component';
import {LanguageService} from '../../shared/services/language.service';
import {AuthenticationService} from '../../core/services/authentication.service';
import {FileSizePipe} from '../../shared/pipes/filesize.pipe';
import {Resource} from '../../core/model/resource.interface';
import {DriverService} from '../../shared/services/driver.service';
import {Driver} from '../../core/model/driver.interface';
import {ErrorService} from '../../shared/services/error.service';
import {SubscriptionService} from '../subscription/subscription.service';
import {TranslateService} from '@ngx-translate/core';
import {RefInterface} from '../../core/model/ref.interface';
import * as frPossessorTypesData from '../../shared/data/fr-constants/possessorTypes.json';
import * as enPossessorTypesData from '../../shared/data/en-constants/possessorTypes.json';
import {DialogModificationComponent} from "./dialogs/dialog-modification/dialog-modification.component";
import {Agent} from "./interfaces/Agent";
import {AdditionnalSupport} from "./interfaces/AdditionnalSupport";
import {Vehicles} from "../../core/model/vehicles.interface";

@Component({
  selector: 'app-modification',
  templateUrl: './modification.component.html',
  styleUrls: ['./modification.component.css']
})
export class ModificationComponent implements OnInit {
  private reference: string;
  private referenceVehicle: string = null;
  private origin: string = null;


  constructor(private formBuilder: FormBuilder,
              private passService: PassService,
              private errorService: ErrorService,
              private spinnerService: SpinnerOverlayService,
              private subscriptionService: SubscriptionService,
              private modificationService: ModificationService,
              private dialog: MatDialog,
              private datePipe: DatePipe,
              private authenticationService: AuthenticationService,
              private filePipe: FileSizePipe,
              private driverService: DriverService,
              private translate: TranslateService,
              private languageService: LanguageService) {
  }

  modificationForm: FormGroup;
  subscription: Subscription;
  vouchersFormArray: FormArray;
  devMode: boolean;
  mode: string;
  validationForm: FormGroup;
  auth: Auth;
  mailValidated: boolean;
  choosen: string;
  private data: Subscription;
  private applicant: any;
  private driver: any;
  informationsRequired = false;
  supportsRequired = false;
  lang;
  public lPossessorTypes: RefInterface[];
  private agents: Agent[] = [];
  errorDisplayed: boolean;

  get possessorTypes(): RefInterface[] {
    return this.lPossessorTypes;
  }

  @ViewChild(MatHorizontalStepper, {static: false}) modificationStepper: MatHorizontalStepper;
  today = new Date();
  additionnalSupports: AdditionnalSupport[] = [];
  isOwnerDriver = false;
  minReleaseDateFilter = (d: Date): boolean => {
    const from = new Date(this.modificationForm.get('entryDate').value);
    return d > from;
  }

  ngOnInit() {
    this.devMode = isDevMode();
    this.mode = 'UPDATE';

    this.modificationForm = this.formBuilder.group({});

    this.validationForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email, Validators.maxLength(80)]],
      recordNumber: [null, [Validators.required]],
      code: [null, [Validators.required]]
    });

    this.vouchersFormArray = this.formBuilder.array([]);

    this.languageService.getCurrentLanguage().subscribe(lang => {
      this.lang = lang;
      switch (lang) {
        case 'fr':
          this.lPossessorTypes = (frPossessorTypesData as any).default;
          break;
        case 'en':
          this.lPossessorTypes = (enPossessorTypesData as any).default;
          break;
      }
    });

    this.translate.get('forms.vouchers.choosen').subscribe(result => {
      this.choosen = result;
    });

    this.errorService.isErrorDisplay().subscribe(showError => this.errorDisplayed = showError);

  }

  getPassRecord() {
    if (this.validationForm.valid) {
      const auth: Auth = {
        username: this.validationForm.get('email').value,
        password: this.validationForm.get('code').value
      };
      this.auth = auth;
      this.spinnerService.show();
      const reference = this.validationForm.get('recordNumber').value;
      this.passService.getUpdatePassRecord(auth.username, reference, auth.password).subscribe((response: any) => {
        if (response && response.status && response.status === 'OK') {
          // console.log(response);
          this.authenticationService.login(this.auth);
          if (response.data) {
            this.data = response.data;
            if (response.data.status) {
              this.mailValidated = true;
              switch (response.data.status) {
                case 'PENDING_INFORMATION':
                  const modification = this.subscriptionService.mapModification(response.data);
                  this.informationsRequired = true;
                  this.supportsRequired = false;
                  this.subscriptionService.setModification(modification);
                  this.referenceVehicle = modification.vehicles[0]["reference"];
                  this.origin = modification.vehicles[0]["origin"];
                  break;
                case 'PENDING_SUPPORT':
                  this.supportsRequired = true;
                  this.informationsRequired = false;
                  this.additionnalSupports = response.data.additionalSupports;
                  this.applicant = response.data.applicant;
                  this.driver = response.data.vehicles[0].driver;
                  this.reference = response.data.reference;
                  this.createAgentInfos();
                  // this.modificationForm.addControl('possessor', this.formBuilder.control(null, Validators.required));
                  break;
              }
            }
            this.spinnerService.hide();
          } else {
            this.spinnerService.hide();
            this.modificationFailed(response.error);
          }
        } else {
          this.spinnerService.hide();
          this.modificationFailed(response.error);
        }
      });
    } else {
      this.validationForm.markAllAsTouched();
    }

  }

  update() {
    this.spinnerService.show();
    if (this.informationsRequired) {
      if (this.modificationForm.get('driverInfos').get('isOwner').value) {
        this.subscription.vehicles[0].driver = null;
      }
      delete this.subscription["payment"];
      delete this.subscription["process"];
      if(this.referenceVehicle != null) {
        this.subscription.vehicles[0].vehicle.reference = this.referenceVehicle;
      }
      if(this.origin != null) {
        this.subscription.vehicles[0].origin = this.origin;
      }
      const reference = this.validationForm.get('recordNumber').value;
      this.passService.updatePass(this.subscription, reference, this.auth.password).subscribe((response: any) => {
        if (response && response.status) {
          if (response.status === 'OK') {
            this.spinnerService.hide();
            this.confirmModification();
          } else {
            this.spinnerService.hide();
            this.modificationFailed(response.error);
          }
        } else {
          this.spinnerService.hide();
          this.modificationFailed('Modification échouée');
        }
      });
    } else if (this.supportsRequired) {
      const resources = this.modificationService.mapResources(this.vouchersFormArray as FormArray);
      this.passService.updatePassResources(this.reference, resources, this.auth.password).subscribe((response: any) => {
        if (response && response.status) {
          if (response.status === 'OK') {
            this.spinnerService.hide();
            this.confirmModification();
          } else {
            this.spinnerService.hide();
            this.modificationFailed(response.error);
          }
        } else {
          this.spinnerService.hide();
          this.modificationFailed('Modification échouée');
        }
      });
    }
  }

  goBack() {
    this.modificationStepper.previous();
    if (this.modificationForm.get('personalInfos') != null && this.modificationStepper.selectedIndex === 0) {
      this.modificationForm.get('personalInfos').get('email').disable();
    }
    if (this.modificationStepper.selectedIndex === 1) {
      if (this.modificationForm.get('driverInfos').get('isOwner').value) {
        this.modificationForm.get('driverInfos').get('lastName').disable();
        this.modificationForm.get('driverInfos').get('firstName').disable();
        this.modificationForm.get('driverInfos').get('birthDate').disable();
        this.modificationForm.get('driverInfos').get('birthCity').disable();
      }
    }
    if (this.modificationStepper.selectedIndex === 2) {
      this.modificationForm.get('vehicleInfos').get('category').disable();
    }
  }

  goForward(stepIndex) {
    switch (stepIndex) {
      case 1:
        if (this.modificationForm.get('personalInfos').valid) {
          this.modificationStepper.selected.completed = true;
          this.modificationForm.get('personalInfos').get('email').enable();
          this.modificationStepper.next();
        }
        break;
      case 2:
        if (this.modificationForm.get('driverInfos').valid) {
          this.modificationStepper.selected.completed = true;
          if (this.modificationForm.get('driverInfos').get('isOwner').value) {
            this.modificationForm.get('driverInfos').get('lastName').enable();
            this.modificationForm.get('driverInfos').get('firstName').enable();
            this.modificationForm.get('driverInfos').get('birthDate').enable();
            this.modificationForm.get('driverInfos').get('birthCity').enable();
          }
          this.modificationStepper.next();
        }
        break;
      case 3:
        if (this.modificationForm.get('vehicleInfos').valid) {
          this.modificationStepper.selected.completed = true;
          this.modificationStepper.next();
          if (this.informationsRequired) {
            this.modificationForm.get('vehicleInfos').get('category').enable();
            this.goForward(4);
          }
        }
        break;
      case 4:
        if (this.informationsRequired && this.modificationForm.valid) {
          this.subscription = this.getValidationData();
          // this.subscriptionService.setModification(this.subscription);
          this.modificationStepper.selected.completed = true;
          this.modificationStepper.next();
        } else if (this.supportsRequired) {
          this.modificationService.setAdditionnalSupports(this.modificationService.mapResources(this.vouchersFormArray as FormArray));
          this.modificationStepper.selected.completed = true;
          this.modificationStepper.next();
        } else {
          this.errorService.showErrors(true);
          // console.log(this.subscription);
        }
        break;
    }
  }

  getValidationData() {
    let data;
    this.subscriptionService.getModification().subscribe(modification => {
      if (modification) {
        const voucherInfos = null;
        const personalInfos = this.modificationForm.get('personalInfos') as FormGroup;
        const driverInfos = this.modificationForm.get('driverInfos') as FormGroup;
        const vehicleInfos = this.modificationForm.get('vehicleInfos') as FormGroup;
        const organisationInfos = this.modificationForm.get('organisationInfos') as FormGroup;
        const destination = modification.vehicles[0].destination;
        modification.vehicles[0] = null;
        let vls = new Vehicles();
        if (this.isOwnerDriver) {
          vls.driver = this.subscriptionService.mapDriver(personalInfos, this.isOwnerDriver, voucherInfos);
        } else {
          vls.driver = this.subscriptionService.mapDriver(driverInfos, this.isOwnerDriver, voucherInfos);
        }
        vls.vehicle = this.subscriptionService.mapVehicle(vehicleInfos);
        vls.destination = destination;
        modification.vehicles[0] = vls;
        modification.applicant = this.subscriptionService.mapApplicant(personalInfos, organisationInfos, voucherInfos);
        data = modification;
        modification = null;
      }
    });
    this.subscriptionService.setSubscription(this.modificationForm);
    return data;
  }


  modificationFailed(message) {
    this.dialog.open(DialogConfirmComponent, {
      width: '500px',
      panelClass: 'my-dialog',
      data: {
        message,
        header: this.lang === 'fr' ? 'Une erreur est survenue' : 'An error has occurred',
      }
    });
  }

  confirmModification() {
    const message = this.lang === 'fr' ? 'Félicitations!' : 'Congratulations!';
    const header = this.lang === 'fr' ? 'Votre mise à jour a été envoyée.' : 'Your update has been sent.';
    this.dialog.open(DialogConfirmComponent, {
      width: '500px',
      panelClass: 'my-dialog',
      data: {
        message: header,
        header: message,
      }
    });
    this.authenticationService.logout();
  }

  confirmResendCode() {
    const message = this.lang === 'fr' ? 'Votre code de validation vous a été envoyé' : 'Your validation code has been sent to you';
    this.dialog.open(DialogConfirmComponent, {
      width: '500px',
      panelClass: 'my-dialog',
      data: {
        message,
      }
    });
  }

  openResendCode() {
    const header = this.lang === 'fr' ? 'Renvoie du code' : 'Resend code';
    const dialogRef = this.dialog.open(ResendCodeComponent, {
      width: '500px',
      panelClass: 'my-dialog',
      data: {
        header,
      }
    });
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response) {
        this.spinnerService.show();
        this.passService.resendCode(response.reference, response.email).subscribe((result: any) => {
          if (result && result.status && result.status === 'OK') {
            this.spinnerService.hide();
            this.confirmResendCode();
          } else {
            this.spinnerService.hide();
            this.modificationFailed(result.error);
          }
        });
      }
    });
  }

  additionnalSupportName(additionnalSupport: Resource) {
    let additionalSupportLabel;
    if (additionnalSupport.status === 'REQUESTED') {
      switch (additionnalSupport.code) {
        case 'SGN00043':
          this.translate.get('forms.voucher.kbis').subscribe(result => {
            additionalSupportLabel = result + ' ' + this.choosen + (additionnalSupport.newName ?
              additionnalSupport.newName : additionnalSupport.name);
          });
          break;
        case 'SGN00042':
          this.translate.get('forms.voucher.pass').subscribe(result => {
            additionalSupportLabel = result + ' ' + this.choosen + (additionnalSupport.newName ?
              additionnalSupport.newName : additionnalSupport.name);
          });
          break;
        case 'SGN00041':
          this.translate.get('forms.voucher.document').subscribe(result => {
            additionalSupportLabel = result + ' ' + this.choosen + (additionnalSupport.newName ?
              additionnalSupport.newName : additionnalSupport.name);
          });
          break;
        case 'SGN00029':
          this.translate.get('forms.voucher.passport').subscribe(result => {
            additionalSupportLabel = result + ' ' + this.choosen + (additionnalSupport.newName ?
              additionnalSupport.newName : additionnalSupport.name);
          });
          break;
        case 'SGN00028':
          this.translate.get('forms.voucher.id').subscribe(result => {
            additionalSupportLabel = result + ' ' + this.choosen + (additionnalSupport.newName ?
              additionnalSupport.newName : additionnalSupport.name);
          });
          break;
      }
    }
    return additionalSupportLabel;
  }

  openDialog(addiSupport: AdditionnalSupport): void {
    const dialogRef = this.dialog.open(DialogModificationComponent, {
      data: {addiSupport: addiSupport, agents: this.agents}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != undefined) {
        // Find index of object in list based on id
        const objectIndex = this.additionnalSupports.findIndex(obj => obj.reference === result.additionnalSupport.reference);
        if (objectIndex !== -1) {
          this.additionnalSupports[objectIndex] = result.additionnalSupport;
        }
        // console.log('this.additionnalSupports => ', this.additionnalSupports);
        this.fileChangeEvent(result.additionnalSupport)
      }
    });
  }

  removeVouchersFormArray(additionnalSupport: AdditionnalSupport) {
    const objectIndex = this.vouchersFormArray.getRawValue().findIndex(obj => obj.code === additionnalSupport.code);
    const addIndex = this.additionnalSupports.findIndex(obj => obj.code === additionnalSupport.code);
    this.vouchersFormArray.removeAt(objectIndex);
    additionnalSupport.haveValidFile = false;
    additionnalSupport.fileName = null;
    additionnalSupport.content = null;
    this.additionnalSupports[addIndex] = additionnalSupport;
  }

  fileChangeEvent = async (additionnalSupport: AdditionnalSupport) => {
    const file = additionnalSupport.content;
    const fileForm = await this.createFileForm(file, additionnalSupport);
    const objectIndex = this.vouchersFormArray.getRawValue().findIndex(obj => obj.code === additionnalSupport.code);
    if (objectIndex !== -1) {
      this.vouchersFormArray.removeAt(objectIndex);
      this.vouchersFormArray.push(fileForm);
    } else {
      this.vouchersFormArray.push(fileForm);
    }
    // console.log("vouchersFormArray ", this.vouchersFormArray.getRawValue());
  }

  createFileForm = async (file, additionnalSupport) => {
    const content = await this.getContentFromFile(file);
    const fileType: string = file.type.split('/').pop();
    additionnalSupport.newName = file.name;
    let person = null;
    let applicant = null;
    if (additionnalSupport.typeperson == 'applicant') {
      applicant = {
        reference: additionnalSupport.referenceperson
      };
    }
    if (additionnalSupport.typeperson == 'person') {
      person = {
        reference: additionnalSupport.referenceperson
      };
    }
    const form = this.formBuilder.group({
      name: file.name,
      mime: file.type,
      content: content,
      code: additionnalSupport.code,
      person: person,
      applicant: applicant,
      fileType: fileType.toUpperCase(),
      type: additionnalSupport.type
    });
    return form;
  }

  getContentFromFile = async (file) => {
    const content = await new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
    return content;
  }

  private createAgentInfos() {
    if (this.applicant && this.applicant != null) {
      this.agents.push({
        lastName: this.applicant.lastName,
        firstName: this.applicant.firstName,
        reference: this.applicant.reference,
        type: 'applicant'
      });
    }
    if (this.driver && this.driver != null) {
      this.agents.push({
        lastName: this.driver.lastName,
        firstName: this.driver.firstName,
        reference: this.driver.reference,
        type: 'person'
      });
    }
  }

  validAllDocument(): boolean {
    return this.additionnalSupports.length != this.vouchersFormArray.getRawValue().length;
  }

  getButtonName(additionnalSupport: AdditionnalSupport): string {
    return additionnalSupport.haveValidFile ? 'common.modifier' : 'common.ajouter';
  }
}
