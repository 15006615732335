<form [formGroup]="parentForm">
  <mat-form-field style="width: 100%;">
    <mat-label>{{countrySelectLabel}}</mat-label>
    <mat-select [disabled]="isDisabled" [formControlName]="countryControlName" required>
      <mat-option>
        <ngx-mat-select-search [formControl]="countryListFilter"
                               [placeholderLabel]="'Chercher un pays'"
                               [noEntriesFoundLabel]="'Pas de résultat trouvé...'"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let country of countryListFilteredList|async" [value]="country" [disabled]="disabled" >
        {{country.name}}
      </mat-option>
    </mat-select>
    <mat-error
      *ngIf="parentForm.get(countryControlName).errors && parentForm.get(countryControlName).errors.required">{{'forms.mandatory'|translate}}</mat-error>
  </mat-form-field>
</form>
