import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import Cropper from 'cropperjs';

@Component({
  selector: 'ng-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent implements OnInit, AfterViewInit {
  @ViewChild('image', {static: false})
  public imageElement: ElementRef;

  @Input('src')
  public imageSource;

  @Output() croppedImageEvent: EventEmitter<string> = new EventEmitter();

  private cropper: Cropper;

  public constructor() {
  }

  public ngAfterViewInit() {
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: true,
      scalable: false,
      cropBoxResizable: false,
      movable: true,
      background: false,
      aspectRatio: 7 / 9,
      cropBoxMovable: false,
      autoCropArea: 1,
      dragMode: 'move',
      viewMode: 1,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas({width: 420, height: 540});
        const canvasData = canvas.toDataURL('image/png');
        this.croppedImageEvent.emit(canvasData);
      }
    });
  }

  public ngOnInit() {
  }
}
