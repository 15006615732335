import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Observable} from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private errorsDisplayed = new BehaviorSubject(false);

  constructor() {
  }

  showErrors(show: boolean) {
    this.errorsDisplayed.next(show);
  }


  isErrorDisplay():Observable<boolean> {
    return this.errorsDisplayed.asObservable();
  }

}
