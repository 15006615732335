import {NgModule} from '@angular/core';
// import {StepPersonalInfosComponent} from './steps/step-personal-infos/step-personal-infos.component';
import {SharedModule} from '../../shared/shared.module';
// import {StepTripInfosComponent} from './steps/step-trip-infos/step-trip-infos.component';
import {StepOwnerInfosComponent} from './steps/step-owner-infos/step-owner-infos.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import {FileSizePipe} from '../../shared/pipes/filesize.pipe';
import {StepVouchersComponent} from './steps/step-vouchers/step-vouchers.component';
import {DialogCropperComponent} from './steps/step-vouchers/dialog-cropper/dialog-cropper.component';
import {ImageCropperComponent} from './steps/step-vouchers/dialog-cropper/image-cropper/image-cropper.component';
import {DatePipe} from '@angular/common';
import {ErrorService} from '../../shared/services/error.service';
import {StepRecapComponent} from './steps/step-recap/step-recap.component';
import { StepDriverInfosComponent } from './steps/step-driver-infos/step-driver-infos.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { StepVehicleInfosComponent } from './steps/step-vehicle-infos/step-vehicle-infos.component';
import {FrenchDateAdapter} from '../../core/adapters/french-date.adapter';
import { StepPaymentInfosComponent } from './steps/step-payment-infos/step-payment-infos.component';
import {OrganisationInfosComponent} from './steps/step-owner-infos/organisation-infos/organisation-infos.component';

@NgModule({
  declarations: [
    StepOwnerInfosComponent,
    StepDriverInfosComponent,
    StepVouchersComponent,
    DialogCropperComponent,
    ImageCropperComponent,
    StepRecapComponent,
    StepVehicleInfosComponent,
    OrganisationInfosComponent,
    StepPaymentInfosComponent],
    imports: [SharedModule, NgxCaptchaModule, MatSlideToggleModule],
    exports: [StepOwnerInfosComponent,
        StepDriverInfosComponent,
        StepVouchersComponent,
      OrganisationInfosComponent,
        StepRecapComponent, StepVehicleInfosComponent, StepPaymentInfosComponent],
  providers: [FileSizePipe, DatePipe, ErrorService, FrenchDateAdapter],
  entryComponents: [DialogCropperComponent]
})

export class SubscriptionModule {
}


