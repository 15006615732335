import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Auth} from '../model/auth.interface';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Auth>;
  public currentUser: Observable<Auth>;

  constructor(private router: Router) {
    this.currentUserSubject = new BehaviorSubject<Auth>(null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Auth {
    return this.currentUserSubject.value;
  }

  login(auth) {
    this.currentUserSubject.next(auth);
  }

  logout() {
    // set current user to null
    this.currentUserSubject.next(null);
    // this.router.navigateByUrl('/');
    window.location.href = '/';
  }

  logoutNoHomeReturn() {
    // set current user to null
    this.currentUserSubject.next(null);
  }
}
