import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {DialogCropperComponent} from './dialog-cropper/dialog-cropper.component';
import {FileSizePipe} from '../../../../shared/pipes/filesize.pipe';
import {DatePipe} from '@angular/common';
import {ErrorService} from '../../../../shared/services/error.service';
import {DialogConfirmComponent} from '../../../../shared/dialog-confirm/dialog-confirm.component';
import {RefInterface} from '../../../../core/model/ref.interface';
import {SubscriptionService} from '../../subscription.service';
import {LanguageService} from '../../../../shared/services/language.service';
import {ModificationService} from '../../../modification/modification.service';
import {DriverService} from '../../../../shared/services/driver.service';
import * as frVoucherTypesData from '../../../../shared/data/fr-constants/voucherTypes.json';
import * as enVoucherTypesData from '../../../../shared/data/en-constants/voucherTypes.json';
import {CompanyApplicantService} from '../../../../shared/services/company-applicant.service';

@Component({
  selector: 'step-vouchers',
  templateUrl: './step-vouchers.component.html',
  styleUrls: ['./step-vouchers.component.css']
})
export class StepVouchersComponent implements OnInit {
  get authTypes(): RefInterface[] {
    return this.lAuthTypes;
  }

  public lVoucherTypes: RefInterface[];
  private lAuthTypes: RefInterface[];
  isCompanyVouchersRequired = false;
  voucherForm: FormGroup;
  @ViewChild('photoFileInput', {static: false})
  imageInput: ElementRef;
  public imageName: string;
  public imageDriverName: string;
  public vehicleCertificateName: string;
  public vehiclePassName: string;
  public ownerIdCardName: string;
  public driverIdCardName: string;
  public ownerPassportName: string;
  public driverPassportName: string;
  public kbisName: string;

  @Input() parentForm: FormGroup;
  @Input() devMode: boolean;
  errorDisplayed: boolean;
  private lang: string;
  public isDriverIdRequired = true;
  showPhoto = true;
  showPhotoDriver = true;
  showId = false;
  showIdDriver = false;
  showPassportDriver = false;
  showPassport = false;
  isKbisRequired = false;

  constructor(
    private driverService: DriverService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private filePipe: FileSizePipe,
    private datePipe: DatePipe,
    private errorService: ErrorService,
    private subscriptionService: SubscriptionService,
    private modificationService: ModificationService,
    private companyApplicantService: CompanyApplicantService,
    private languageService: LanguageService
  ) {

  }

  ngOnInit() {
    this.voucherForm = this.formBuilder.group({
      voucherTypeOwner: [null, Validators.required],
      photo: [null, Validators.required],
      ownerIdCard: [null, Validators.required],
      vehicleCertificate: [null, Validators.required],
      vehiclePass: [null, Validators.required],
      kbis: [null],
      voucherTypeDriver: [null],
      driverIdCard: [null],
      photoDriver: [null],
    });
    this.driverService.isDriverIdRequired().subscribe(required => {
      this.voucherForm.reset();
      this.activateVoucherDriver(required);
      console.log('is owner ! : ', required);
    });

    // this.activateVoucherDriver(this.parentForm.get('driverInfos').get('isOwner').value);

    this.errorService.isErrorDisplay().subscribe(showError => this.errorDisplayed = showError);
    this.companyApplicantService.isCompanyVouchersRequired().subscribe(required => {
      this.isCompanyVouchersRequired = required;
      if (required) {
        this.voucherForm.removeControl('voucherTypeOwner');
        this.voucherForm.removeControl('photo');
        this.voucherForm.removeControl('ownerIdCard');
      } else {
        this.voucherForm.addControl('voucherTypeOwner', this.formBuilder.control(null, Validators.required));
        this.voucherForm.addControl('photo', this.formBuilder.control(null, Validators.required));
        this.voucherForm.addControl('ownerIdCard', this.formBuilder.control(null, Validators.required));
      }
    });

    this.languageService.getCurrentLanguage().subscribe(lang => {
      this.lang = lang;
      const selectedVoucherTypeOwner = this.voucherForm.get('voucherTypeOwner').value;
      const selectedVoucherTypeDriver = this.voucherForm.get('voucherTypeDriver').value;
      switch (lang) {
        case 'fr':
          this.lVoucherTypes = (frVoucherTypesData as any).default;
          break;
        case 'en':
          this.lVoucherTypes = (enVoucherTypesData as any).default;
          break;
      }
      if (selectedVoucherTypeOwner) {
        this.voucherForm.get('voucherTypeOwner').setValue(
          this.lVoucherTypes.filter(item => item.code === selectedVoucherTypeOwner.code)[0]);
      }
      if (selectedVoucherTypeDriver) {
        this.voucherForm.get('voucherTypeDriver').setValue(
          this.lVoucherTypes.filter(item => item.code === selectedVoucherTypeDriver.code)[0]);
      }
    });
    this.parentForm.addControl('vouchersInfos', this.voucherForm);
  }

  activateVoucherDriver(isOwner) {
    if (isOwner) {
      this.isDriverIdRequired = true;
      this.voucherForm.get('voucherTypeDriver').setValidators([Validators.required]);
      this.voucherForm.get('driverIdCard').setValidators([Validators.required]);
      this.voucherForm.get('photoDriver').setValidators([Validators.required]);
    } else {
      this.isDriverIdRequired = false;
      this.voucherForm.get('voucherTypeDriver').setValidators([]);
      this.voucherForm.get('driverIdCard').setValidators([]);
      this.voucherForm.get('photoDriver').setValidators([]);
    }
    this.voucherForm.get('voucherTypeDriver').updateValueAndValidity();
    this.voucherForm.get('driverIdCard').updateValueAndValidity();
    this.voucherForm.get('photoDriver').updateValueAndValidity();

  }

  fileChangeEvent = async (event, docType, subType?) => {
    const file = event.target.files[0];
    let fileForm;
    const fileExt: string = file.type.split('/').pop();

    if (docType === 'PHOTO') {
      if (fileExt.toLowerCase() !== 'jpeg' && fileExt.toLowerCase() !== 'png' && fileExt.toLowerCase() !== 'bmp') {
        alert('Types de fichier autorisés: JPEG, PNG');
      }
      if (this.filePipe.transform(file.size) > 1) {
        alert('Veuillez choisir un fichier de taille maximale 1MO');
        event.srcElement.value = null;
        if (subType === 'ownerPhoto') {
          this.imageName = null;
        } else if (subType === 'driverPhoto') {
          this.imageDriverName = null;
        }
      } else {
        const imageFile = event.target.files[0];
        if (subType === 'ownerPhoto') {
          this.imageName = imageFile.name;
        } else if (subType === 'driverPhoto') {
          this.imageDriverName = imageFile.name;
        }
        this.openDialog(event, file, subType);
      }
    } else {
      if (fileExt.toLowerCase() !== 'jpeg' && fileExt.toLowerCase() !== 'png'
        && fileExt.toLowerCase() !== 'pdf') {
        alert('Types de fichier autorisés: JPEG, PNG, PDF');
      }
      if (this.filePipe.transform(file.size) > 2) {
        alert('Veuillez choisir un fichier de taille maximale 2MO');
        event.srcElement.value = null;
      } else {

        switch (docType) {
          case 'CERTIFICATE':
            switch (subType) {
              case 'document':
                fileForm = await this.createFileForm(file, docType);
                this.vehicleCertificateName = file.name;
                fileForm.patchValue({
                  code: 'SGN00041'
                });
                this.voucherForm.setControl('vehicleCertificate', fileForm);
                break;
              case 'pass':
                fileForm = await this.createFileForm(file, docType);
                this.vehiclePassName = file.name;
                fileForm.patchValue({
                  code: 'SGN00042'
                });
                this.voucherForm.setControl('vehiclePass', fileForm);
                break;
              case 'kbis':
                fileForm = await this.createFileForm(file, docType);
                this.kbisName = file.name;
                fileForm.patchValue({
                  code: 'SGN00043'
                });
                this.voucherForm.setControl('kbis', fileForm);
                break;
            }
            break;
          case 'PASSPORT':
            switch (subType) {
              case 'ownerPassport':
                fileForm = await this.createFileForm(file, docType);
                this.ownerPassportName = file.name;
                fileForm.patchValue({
                  code: 'SGN00029'
                });
                this.voucherForm.setControl('ownerIdCard', fileForm);
                break;
              case 'driverPassport':
                fileForm = await this.createFileForm(file, docType);
                this.driverPassportName = file.name;
                fileForm.patchValue({
                  code: 'SGN00029'
                });
                this.voucherForm.setControl('driverIdCard', fileForm);
                break;
            }
            break;
          case 'ID':
            switch (subType) {
              case 'ownerIdCard':
                fileForm = await this.createFileForm(file, docType);
                this.ownerIdCardName = file.name;
                fileForm.patchValue({
                  code: 'SGN00028'
                });
                this.voucherForm.setControl('ownerIdCard', fileForm);
                break;
              case 'driverIdCard':
                fileForm = await this.createFileForm(file, docType);
                this.driverIdCardName = file.name;
                fileForm.patchValue({
                  code: 'SGN00028'
                });
                this.voucherForm.setControl('driverIdCard', fileForm);
                break;
            }
            break;
        }
      }
    }
  }

  createFileForm = async (file, docType) => {
    const content = await this.getContentFromFile(file);
    const fileType: string = file.type.split('/').pop();
    return this.formBuilder.group({
      type: docType,
      name: file.name,
      mime: file.type,
      content,
      issueDate: this.datePipe.transform(Date.now(), 'dd/MM/yyyy'),
      expiryDate: this.datePipe.transform(Date.now(), 'dd/MM/yyyy'),
      code: null,
      fileType: fileType.toUpperCase(),
    });
  }

  getContentFromFile = async (file) => {
    return await new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
  }

  openDialog(event, file, subType) {
    const dialogRef = this.dialog.open(DialogCropperComponent, {
      width: '500px',
      panelClass: 'my-dialog',
      data: event.target.files[0]
    });

    dialogRef.afterClosed().subscribe(result => {
          if (!result.reset) {
            const photoFileForm: any = this.createPhotoForm(file, result.data);
            photoFileForm.patchValue({
              code: 'SGN00005'
            });
            if (subType === 'ownerPhoto') {
              this.voucherForm.setControl('photo', photoFileForm);
            } else if (subType === 'driverPhoto') {
              this.voucherForm.setControl('photoDriver', photoFileForm);
            }
          } else {
            event.srcElement.value = null;
            this.imageName = undefined;
            this.imageDriverName = undefined;
          }
    });
  }

  createPhotoForm(file, content) {
    const fileType: string = file.type.split('/').pop();
    const form = this.formBuilder.group({
      type: 'PHOTO',
      name: file.name,
      mime: file.type,
      content,
      code: null,
      issueDate: this.datePipe.transform(Date.now(), 'dd/MM/yyyy'),
      expiryDate: this.datePipe.transform(Date.now(), 'dd/MM/yyyy'),
      fileType: fileType.toUpperCase(),
    });
    return form;
  }

  confirmAction() {
    const message = this.lang === 'fr' ?
      'Une enveloppe au format A4 préaffranchie en' +
      '  colis suivi DHL et libellée à votre nom devra être' +
      '  impérativement joint au passeport pour ce' +
      '  choix de livraison.'
      :
      'An envelope in A4 format ,' +
      ' stamped with label of registered post and' +
      ' denominated with your name and address must be' +
      ' imperatively attached to the passport for this choice' +
      ' of delivery.';
    this.dialog.open(DialogConfirmComponent, {
      width: '500px',
      panelClass: 'my-dialog',
      data: {
        message
      }
    });
  }

  get voucherTypes(): RefInterface[] {
    return this.lVoucherTypes;
  }

  selectVoucherType(event: any, user: string) {
    switch (user) {
      case 'owner':
        if (event.value === 'PASSPORT') {
          this.showPassport = true;
          this.showId = false;
        } else {
          this.showId = true;
          this.showPassport = false;
        }
        break;
      case 'driver':
        if (event.value === 'PASSPORT') {
          this.showPassportDriver = true;
          this.showIdDriver = false;
        } else {
          this.showIdDriver = true;
          this.showPassportDriver = false;
        }
        break;
    }
  }
}
