import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  currentUser = null;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {

    this.authenticationService.currentUser.subscribe(user => this.currentUser = user
    );
  }


  canActivate() {
    if (this.currentUser != null) {
      // authorised so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['']);
    return false;
  }
}
