import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {CountryInterface} from "../../core/model/country.interface";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {Subject} from "rxjs/internal/Subject";
import {FormControl, FormGroup} from "@angular/forms";
import * as frCountries from '../data/fr-constants/countries.json';
import * as enCountries from '../../shared/data/en-constants/countries.json';
import {LanguageService} from "../services/language.service";
import {ModificationService} from "../../components/modification/modification.service";

@Component({
  selector: 'select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.css']
})
export class SelectCountryComponent implements OnInit, OnChanges, OnDestroy {

  countryList: CountryInterface[];

  @Input() parentForm: FormGroup;
  @Input() countryControlName: string;
  @Input() countrySelectLabel: string;
  @Input() mode: string;
  @Input() organisation: boolean;
  @Input() parent: boolean;
  @Input() disabled: boolean;
  public countryListFilter: FormControl = new FormControl();
  public countryListFiltered: ReplaySubject<CountryInterface[]> = new ReplaySubject<CountryInterface[]>(1);

  protected _onDestroy = new Subject<void>();
  isDisabled: false;


  constructor(private languageService: LanguageService,
              private modificationService: ModificationService) {
  }

  ngOnInit() {
    //Change the countries according to language and assign already selected value
    this.languageService.getCurrentLanguage().subscribe(lang => {
      const selectedCountry = this.parentForm.get(this.countryControlName).value;
      switch (lang) {
        case 'fr':
          this.countryList = [...(frCountries as any).default];
          break;
        case 'en':
          this.countryList = [...(enCountries as any).default];
          break;
      }

      if (selectedCountry) {
        const filteredList = this.countryList.filter(item => item.code === selectedCountry.code);
        if (filteredList.length > 0) {
          this.parentForm.get(this.countryControlName).setValue(filteredList[0]);
        } else {
          //if the selected country doesnt exist in the selected language
          this.parentForm.get(this.countryControlName).setValue(null);
        }
      }

      this.countryList.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });

      this.countryListFiltered.next(this.countryList.slice());
      this.countryListFilter.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterCountries();
        });
    });
  }


  get countryListFilteredList() {
    return this.countryListFiltered;
  }

  protected filterCountries() {
    if (!this.countryList) {
      return;
    }
    // get the search keyword
    let search = this.countryListFilter.value;
    if (!search) {
      this.countryListFiltered.next(this.countryList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countries
    this.countryListFiltered.next(
      this.countryList.filter(country => country.name.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let disabledChange = changes['disabled'];
    if (disabledChange && disabledChange.currentValue) {
      this.isDisabled = disabledChange.currentValue;
    } else {
      this.isDisabled = false;
    }
  }

}
