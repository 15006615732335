import {AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';

export class DateValidator {

  static pastDate(control: FormControl): { [key: string]: any } {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    if (control.value < today)
      return {"pastDate": true};

    return null;
  }

  static futurDate(control: FormControl): { [key: string]: any } {
    const today = new Date();
    if (control.value > today)
      return {"futurDate": true};
    return null;
  }

  static lessThanPlusMonthDate(control: FormControl): { [key: string]: any } {
    const today = new Date();
    const toDayPlusMonth = new Date();
    toDayPlusMonth.setMonth(toDayPlusMonth.getMonth() + 1);

    if (control.value < toDayPlusMonth)
      return {"lessThanPlusMonthDate": true};

    return null;
  }

  static reversedDatesInterval(form: FormGroup): { [key: string]: any } {
    if (form.get('entryDate').value >= form.get('releaseDate').value)
      return {"reversedDatesInterval": true};
    return null;
  }

  static underAge(control: FormControl): { [key: string]: any } {
    if (DateValidator.calculateAge(control.value) < 18) {
      return {"underAge": true};
    }
    return null;
  }

  static adult(control: AbstractControl) {
    if (control.value != null && DateValidator.calculateAge(control.value) < 18) {
      return {
        invalidAdult: true
      };
    }
    return null;
  }

  static calculateAge(birthdate): number {
    if (birthdate == null)
      return -1;
    const bdate = new Date(birthdate);
    const timeDiff = Math.abs(Date.now() - bdate.getTime());
    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    return age;
  }
}
