import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private currentLanguage = new BehaviorSubject('fr');

  constructor() {
  }

  setLanguage(lang: string) {
    this.currentLanguage.next(lang);
  }


  getCurrentLanguage(): Observable<string> {
    return this.currentLanguage.asObservable();
  }

}
