import {Component, ViewEncapsulation} from '@angular/core';
import {DateAdapter, MatIconRegistry} from "@angular/material";
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from "@angular/platform-browser";
import {LanguageService} from "./shared/services/language.service";
import {AuthenticationService} from "./core/services/authentication.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  constructor(private dateAdapter: DateAdapter<any>,
              private translate: TranslateService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private languageService: LanguageService) {

    this.matIconRegistry.addSvgIcon(
      'globe_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/earth.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fr_flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/france.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'uk_flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/uk.svg')
    );

    this.setFrench();
    translate.addLangs(['fr', 'en']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
      this.languageService.setLanguage(browserLang.match(/en|fr/) ? browserLang : 'fr');
    } else {
      localStorage.setItem('locale', 'fr');
      translate.setDefaultLang('fr');
      this.languageService.setLanguage('fr');
    }

  }

  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }


  setFrench() {
    // Set language of Datepicker
    this.dateAdapter.setLocale('fr');
  }
}
