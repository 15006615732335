import {Component, Input, OnInit} from '@angular/core';
import {SubscriptionService} from '../../subscription.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {ModificationService} from '../../../modification/modification.service';
import {Resource} from '../../../../core/model/resource.interface';

@Component({
  selector: 'step-recap',
  templateUrl: './step-recap.component.html',
  styleUrls: ['./step-recap.component.css']
})
export class StepRecapComponent implements OnInit {
  details: any = null;
  @Input() mode: string;
  additionnalSupports = null;

  constructor(private subscriptionService: SubscriptionService,
              private modificationService: ModificationService,
              private authenticationService: AuthenticationService) {

  }

  email;

  parseAddress(address) {

    let addressLine = '';
    addressLine = (address.streetName ? address.streetName + ', ' : '') +
      (address.additional ? address.additional + ', ' : '') +
      (address.town ? address.town + ', ' : '') +
      (address.state ? address.state + ', ' : '')
      + (address.country && address.country.name ? address.country.name : '');

    return addressLine;
  }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(user => {
      if (user) {
        this.email = user.username;
      }
    });
    this.subscriptionService.getSubscription().subscribe(subscription => {
      if (subscription) {
        this.details = subscription.value;
      }
    });
    this.modificationService.getAdditionnalSupports().subscribe(additionnalSupports => {
      if (this.mode === 'UPDATE' && additionnalSupports && additionnalSupports.length > 0) {
        this.additionnalSupports = additionnalSupports;
      }
    });
  }

  openFile(file) {
    const content = file.content;
    const linkSource = content;
    const downloadLink = document.createElement('a');
    const fileName = file.name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  getLabel(additionnalSupport: Resource) {
    return this.modificationService.getLabel(additionnalSupport);
  }
}
