import {ChangeDetectorRef, Component, isDevMode, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MatDialog, MatHorizontalStepper} from '@angular/material';
import {ErrorService} from '../../shared/services/error.service';
import {Subscription} from '../../core/model/subscription.interface';
import {SubscriptionService} from './subscription.service';
import {PassService} from '../../shared/services/pass.service';
import {DialogConfirmComponent} from '../../shared/dialog-confirm/dialog-confirm.component';
import {AuthenticationService} from '../../core/services/authentication.service';
import {Router} from '@angular/router';
import {RedirectService} from '../../shared/services/redirect.service';
import {SpinnerOverlayService} from '../../shared/services/spinner-overlay.service';
import {LanguageService} from '../../shared/services/language.service';
import {Driver} from '../../core/model/driver.interface';
import {DriverService} from '../../shared/services/driver.service';
import {Vehicles} from '../../core/model/vehicles.interface';
import {Payment} from '../../core/model/payment.interface';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
              private errorService: ErrorService,
              private subscriptionService: SubscriptionService,
              private passService: PassService,
              private authenticationService: AuthenticationService,
              private router: Router,
              private _adapter: DateAdapter<any>,
              private dialog: MatDialog,
              private redirectService: RedirectService,
              private spinnerService: SpinnerOverlayService,
              private driverService: DriverService,
              private languageService: LanguageService,
              private cdRef: ChangeDetectorRef
  ) {
  }

  form: FormGroup;
  devMode: boolean;
  mode: string;
  subscription: Subscription;
  private isOwnerDriver = false;
  private lang: string;

  @ViewChild(MatHorizontalStepper, {static: false}) stepper: MatHorizontalStepper;

  ngOnInit() {
    this.devMode = isDevMode();
    this.mode = 'UPDATE';

    this.createForm();
    this._adapter.setLocale('fr');

    this.languageService.getCurrentLanguage().subscribe(lang => this.lang = lang);
  }

  createForm() {
    this.form = this.formBuilder.group({});
  }

  goBack() {
    this.stepper.previous();
    if (this.stepper.selectedIndex === 0) {
      this.form.get('personalInfos').get('email').disable();
    }
    if (this.stepper.selectedIndex === 1) {
      if (this.form.get('driverInfos').get('isOwner').value && this.form.get('organisationInfos') == null) {
        this.form.get('driverInfos').get('lastName').disable();
        this.form.get('driverInfos').get('firstName').disable();
        this.form.get('driverInfos').get('birthDate').disable();
        this.form.get('driverInfos').get('birthCity').disable();
      }
    }
  }

  goForward(stepIndex) {
    this.subscriptionService.setSubscription(this.form);
    switch (stepIndex) {
      case 1:
        this.form.get('personalInfos').markAllAsTouched();
        if (this.form.get('organisationInfos') != null) this.form.get('organisationInfos').markAllAsTouched();
        if (this.form.get('personalInfos').get('applicant').value) {
          if (this.form.get('personalInfos').valid && this.form.get('organisationInfos').valid) {
            this.stepper.selected.completed = true;
            this.form.get('personalInfos').get('email').enable();
            this.stepper.next();
          }
          this.form.get('driverInfos').get('lastName').enable();
          this.form.get('driverInfos').get('firstName').enable();
          this.form.get('driverInfos').get('birthDate').enable();
          this.form.get('driverInfos').get('birthCity').enable();
          this.form.get('driverInfos').markAllAsTouched();
        } else {
          if (this.form.get('personalInfos').valid) {
            this.stepper.selected.completed = true;
            this.form.get('personalInfos').get('email').enable();
            this.stepper.next();
          }
        }
        break;
      case 2:
        this.form.get('driverInfos').markAllAsTouched();
        if (this.form.get('driverInfos').valid) {
          this.stepper.selected.completed = true;
          if (this.form.get('driverInfos').get('isOwner').value) {
            this.form.get('driverInfos').get('lastName').enable();
            this.form.get('driverInfos').get('firstName').enable();
            this.form.get('driverInfos').get('birthDate').enable();
            this.form.get('driverInfos').get('birthCity').enable();
          }
          this.stepper.next();
        }
        break;
      case 3:
        this.form.get('vehicleInfos').markAllAsTouched();
        if (this.form.get('vehicleInfos').valid) {
          this.stepper.selected.completed = true;
          this.stepper.next();
        }
        break;
      case 4:
        this.form.get('vouchersInfos').markAllAsTouched();
        if (this.form.get('vouchersInfos').valid) {
          this.subscription = this.getValidationData();
          this.stepper.selected.completed = true;
          this.stepper.next();
        }
        break;
      case 5:
        this.stepper.selected.completed = true;
        this.stepper.next();
        break;
      case 6:
        this.form.get('paymentInfos').markAllAsTouched();
        if (this.form.valid) {
          this.subscription.payment = this.getValidationDataPayment();
          this.validate();
        } else {
          this.errorService.showErrors(true);
          // console.log(this.subscription);
        }
        break;
    }
  }

  getValidationData() {
    const personalInfos = this.form.get('personalInfos') as FormGroup;
    const driverInfos = this.form.get('driverInfos') as FormGroup;
    const vehicleInfos = this.form.get('vehicleInfos') as FormGroup;
    const voucherInfos = this.form.get('vouchersInfos') as FormGroup;
    const organisationInfos = this.form.get('organisationInfos') as FormGroup;
    const vehicles: Vehicles[] = [];
    const dataVehicles: Vehicles = new Vehicles();
    if (driverInfos.get('isOwner').value) {
      dataVehicles.driver = this.subscriptionService.mapDriver(personalInfos, driverInfos.get('isOwner').value, voucherInfos);
    } else {
      dataVehicles.driver = this.subscriptionService.mapDriver(driverInfos, driverInfos.get('isOwner').value, voucherInfos);
    }
    dataVehicles.destination = vehicleInfos.get('destination').value.code;
    dataVehicles.vehicle = this.subscriptionService.mapVehicle(vehicleInfos);
    vehicles.push(dataVehicles);
    const data: Subscription = {
      resources: this.subscriptionService.mapResources(voucherInfos),
      applicant: this.subscriptionService.mapApplicant(personalInfos, organisationInfos, voucherInfos),
      vehicles
    };
    this.subscriptionService.setSubscription(this.form);
    return data;
  }

  getValidationDataPayment() {
    const paymentInfos = this.form.get('paymentInfos') as FormGroup;
    const data: Payment = {
      type: paymentInfos.get('type').value.code,
      transaction: {
        source: paymentInfos.get('source').value,
        number: paymentInfos.get('number').value,
      }
    };
    this.subscriptionService.setSubscription(this.form);
    return data;
  }

  validate() {
    this.spinnerService.show();
    if (this.form.get('driverInfos').get('isOwner').value) {
      this.subscription.vehicles[0].driver = null;
    }
    this.passService.createPass(this.subscription).subscribe(
      (result: any) => {
        if (result && result.status && result.status === 'OK') {
          if (result.data) {
            const message = 'Référence du dossier: ' + result.data.reference;
            this.spinnerService.hide();
            this.creationSuccess(message);
            this.authenticationService.logout();
          }
        } else {
          this.spinnerService.hide();
          this.creationFailed(result.error);
        }
      }
    );
  }

  creationSuccess(message) {
    this.dialog.open(DialogConfirmComponent, {
      width: '500px',
      panelClass: 'my-dialog',
      data: {
        message,
        header: this.lang === 'fr' ? 'Demande effectuée avec succès' : 'Demand performed with success',
      }
    });
  }

  creationFailed(message) {
    this.dialog.open(DialogConfirmComponent, {
      width: '500px',
      panelClass: 'my-dialog',
      data: {
        message,
        header: this.lang === 'fr' ? 'Une erreur est survenue' : 'An error has occurred',
      }
    });
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

}
