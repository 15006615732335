import {Component, Input, OnInit} from '@angular/core';
import {RefInterface} from '../../../../core/model/ref.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubscriptionService} from '../../subscription.service';
import {LanguageService} from '../../../../shared/services/language.service';
import * as frPaymentTypeData from '../../../../shared/data/fr-constants/paymentType.json';
import * as enPaymentTypeData from '../../../../shared/data/en-constants/paymentType.json';

@Component({
  selector: 'step-payment',
  templateUrl: './step-payment-infos.component.html',
  styleUrls: ['./step-payment-infos.component.css']
})
export class StepPaymentInfosComponent implements OnInit {

  get typeList(): RefInterface[] {
    return this.lTypeList;
  }

  @Input() parentForm: FormGroup;
  @Input() devMode: boolean;
  @Input() mode: string;

  private lTypeList: RefInterface[];

  paymentInfosForm: FormGroup;

  phonePattern = '^[0-9]{3,15}$|\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|' +
    '6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{1,14}$';

  amounts = [
    {
      code: 'MOT',
      amount: '40$'
    },
    {
      code: 'SED',
      amount: '60$'
    },
    {
      code: 'BUS',
      amount: '100$'
    },
    {
      code: 'BER',
      amount: '60$'
    },
    {
      code: 'SUV',
      amount: '70$'
    },
    {
      code: 'C10',
      amount: '100$'
    },
    {
      code: 'C12',
      amount: '120$'
    },
    {
      code: 'AEL',
      amount: '150$'
    }
  ];

  constructor(private formBuilder: FormBuilder,
              private subscriptionService: SubscriptionService,
              private languageService: LanguageService) {

  }

  ngOnInit() {

    this.initForm();

    this.languageService.getCurrentLanguage().subscribe(lang => {
      const selectedType = this.paymentInfosForm.get('type').value;
      switch (lang) {
        case 'fr':
          this.lTypeList = (frPaymentTypeData as any).default;
          break;
        case 'en':
          this.lTypeList = (enPaymentTypeData as any).default;
          break;
      }

      if (selectedType) {
        this.paymentInfosForm.get('type').setValue(this.lTypeList.filter(item => item.code === selectedType.code)[0]);
      }
    });

    this.parentForm.get('vehicleInfos').get('category').valueChanges.subscribe(category => {
      this.paymentInfosForm.get('amount').setValue(this.amounts.filter(item => item.code === category.code)[0].amount);
      this.paymentInfosForm.get('amount').disable();
    });

    this.parentForm.addControl('paymentInfos', this.paymentInfosForm);
  }

  initForm() {
    this.paymentInfosForm = this.formBuilder.group({
      type: [null, Validators.required],
      source: [null, [Validators.required, Validators.maxLength(15), Validators.pattern(this.phonePattern)]],
      number: [null, Validators.required],
      amount: [null]
    });
  }

}
