import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubscriptionService} from '../../components/subscription/subscription.service';
import {LanguageService} from '../services/language.service';
import {CountryInterface} from '../../core/model/country.interface';
import * as frCountryListData from '../data/fr-constants/countries.json';
import * as enCountryListData from '../data/en-constants/countries.json';

@Component({
  selector: 'gn-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  @Input() parentFormGroup: FormGroup;
  @Input() mode: string;

  private lCountryList: CountryInterface[];

  addressForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private subscriptionService: SubscriptionService,
              private languageService: LanguageService) {
  }

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      streetName: [null, [Validators.required, Validators.maxLength(100)]],
      additional: [null, Validators.maxLength(100)],
      /*zipCode: [null, [Validators.required, Validators.maxLength(this.zipCodeMaxLength)]],
      postBox: [null, Validators.max(99999999)],*/
      state: [null, [Validators.maxLength(100)]],
      town: [null, [Validators.required, Validators.maxLength(100)]],
      country: [null, Validators.required],
    });

    this.languageService.getCurrentLanguage().subscribe(lang => {
      const selectedCategory = this.addressForm.get('country').value;
      switch (lang) {
        case 'fr':
          this.lCountryList = (frCountryListData as any).default;
          break;
        case 'en':
          this.lCountryList = (enCountryListData as any).default;
          break;
      }
    });

    this.addressForm.get('country').valueChanges.subscribe(newValue => {
      if (newValue) {
        switch (newValue.code) {
          case 'BF':
            /*this.zipCodeMaxLength = 2;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
          case 'BA':
          case 'TW':
          case 'MG':
          case 'OM':
          case 'CZ':
            /*this.zipCodeMaxLength = 3;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
          case 'ZA':
          case 'AU':
          case 'AT':
          case 'AZ':
          case 'BE':
          case 'DK':
          case 'HU':
          case 'LU':
          case 'MK':
          case 'MD':
          case 'NL':
          case 'PH':
          case 'CH':
          case 'TN':
            /*this.zipCodeMaxLength = 4;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
          case 'DZ':
          case 'DE':
          case 'SA':
          case 'BY':
          case 'HR':
          case 'DO':
          case 'ES':
          case 'FR':
          case 'GT':
          case 'IT':
          case 'LT':
          case 'MY':
          case 'MA':
          case 'MX':
          case 'ME':
          case 'RS':
          case 'LK':
          case 'SE':
          case 'UA':
            /*this.zipCodeMaxLength = 5;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
          case 'AD':
            /*this.zipCodeMaxLength = 5;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
          case 'BR':
          case 'KH':
          case 'PL':
          case 'VE':
          case 'NR':
            /*this.zipCodeMaxLength = 6;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
          case 'CN':
          case 'CO':
          case 'EC':
          case 'GR':
          case 'IN':
          case 'KZ':
          case 'KG':
          case 'UZ':
          case 'RO':
          case 'SG':
          case 'SK':
            /*this.zipCodeMaxLength = 6;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
          case 'BJ':
          case 'CA':
          case 'HT':
            /*this.zipCodeMaxLength = 7;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
          case 'CM':
          case 'FI':
          case 'IL':
            /*this.zipCodeMaxLength = 7;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
          case 'GI':
          case 'GB':
            /*this.zipCodeMaxLength = 9;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
          default:
            /*this.zipCodeMaxLength = 8;
            this.addressForm.get('zipCode').setValidators([Validators.required, Validators.maxLength(this.zipCodeMaxLength)]);*/
            break;
        }
      }
    });
    this.subscriptionService.getModification().subscribe(modification => {
      if (this.mode === 'UPDATE' && modification != null && modification.applicant != null) {
        if (modification.applicant.address != null) {
          const address = modification.applicant.address;
          this.addressForm.get('streetName').setValue(address.streetName);
          this.addressForm.get('additional').setValue(address.additional);
          /*this.addressForm.get('zipCode').setValue(address.zipCode);
          this.addressForm.get('postBox').setValue(address.postBox);*/
          this.addressForm.get('state').setValue(address.state);
          this.addressForm.get('town').setValue(address.city);
          this.addressForm.get('country').setValue(this.lCountryList.filter(item => item.code === address.country)[0]);
        }
      }
    });
    this.parentFormGroup.setControl('address', this.addressForm);

  }

  zipCodeMaxLength = 8;

  toUpperCase(key: string) {
    const value = this.addressForm.get(key).value;
    this.addressForm.get(key).setValue(value.toUpperCase());
  }
}
