<form [formGroup]="contactForm" style="margin-top: 3%">
  <div class="container">
    <mat-card class="container" style="margin-bottom: 2%;">
      <mat-card-header>
        <mat-card-title>
          <div style="vertical-align: middle">
            <mat-icon>local_post_office</mat-icon>&nbsp; <strong>{{'forms.contact.title'|translate}}</strong>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="container">
        <mat-form-field class="col col-md-4">
          <input matInput placeholder="{{'forms.infos.email'|translate}}" formControlName="email" maxlength="100"
                 required>
          <mat-error *ngIf="contactForm.get('email').errors && contactForm.get('email').errors.required">
            {{'forms.mandatory'| translate}}
          </mat-error>
          <mat-error *ngIf="contactForm.get('email').errors && contactForm.get('email').errors.email">
            {{'login.wrongFormat'|translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col col-md-4">
          <input matInput placeholder="{{'forms.consultation.recordNumber'|translate}}" maxlength="50"
                 formControlName="recordNumber" required>
          <mat-error
            *ngIf="contactForm.get('recordNumber').errors && contactForm.get('recordNumber').errors.required">
            {{'forms.mandatory'|translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col col-md-12">
          <mat-label>{{'contact.mailObject'|translate}}</mat-label>
          <input matInput
                    cdkTextareaAutosize
                    formControlName="mailObject"
                    required/>
          <mat-error *ngIf="contactForm.get('mailObject').errors && contactForm.get('mailObject').errors.required">
            {{'forms.mandatory'| translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col col-md-12">
          <mat-label>{{'contact.mailContent'|translate}}</mat-label>
          <textarea matInput
                    cdkTextareaAutosize
                    formControlName="content"
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="10" required></textarea>
          <mat-error *ngIf="contactForm.get('content').errors && contactForm.get('content').errors.required">
            {{'forms.mandatory'| translate}}
          </mat-error>
        </mat-form-field>
        <div class="col-md-4">
          <ngx-recaptcha2 #captchaElem
                          [siteKey]="siteKey"
                          (reset)="handleReset()"
                          (expire)="handleExpire()"
                          (load)="handleLoad()"
                          [hl]="'fr'"
                          (success)="handleSuccess($event)"
                          [useGlobalDomain]="true"
                          formControlName="captcha">
          </ngx-recaptcha2>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</form>
<div class="first-stepping container">
  <button class="mat-raised-button submit-button width" mat-button>
    {{'forms.contact.send'|translate}}
  </button>
</div>
