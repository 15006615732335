import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {catchError, retry} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {SpinnerOverlayService} from '../../shared/services/spinner-overlay.service';
import {DialogConfirmComponent} from '../../shared/dialog-confirm/dialog-confirm.component';
import {MatDialog} from '@angular/material';
import {LanguageService} from '../../shared/services/language.service';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ErrorIntercept implements HttpInterceptor {

  constructor(private spinnerService: SpinnerOverlayService,
              private languageService: LanguageService,
              private dialog: MatDialog) {
  }
  lang;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          this.spinnerService.hide();
          this.creationFailed();
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
          }
          // console.log(errorMessage);
          return throwError(errorMessage);
        })
      );
  }

  creationFailed() {
    this.languageService.getCurrentLanguage().subscribe(lang => {
      this.dialog.open(DialogConfirmComponent, {
        width: '500px',
        panelClass: 'my-dialog',
        data: {
          message: lang === 'fr' ? 'Une erreur est survenue' : 'An error has occurred',
        }
      });
    });
  }
}
