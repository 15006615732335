import {Component, Input, OnInit} from '@angular/core';
import {RefInterface} from '../../../../core/model/ref.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubscriptionService} from '../../subscription.service';
import {LanguageService} from '../../../../shared/services/language.service';
import * as frCategoryData from '../../../../shared/data/fr-constants/categories.json';
import * as enCategoryData from '../../../../shared/data/en-constants/categories.json';
import * as frCountryListData from '../../../../shared/data/fr-constants/countries.json';
import * as enCountryListData from '../../../../shared/data/en-constants/countries.json';
import {CountryInterface} from '../../../../core/model/country.interface';

@Component({
  selector: 'step-vehicle-infos',
  templateUrl: './step-vehicle-infos.component.html',
  styleUrls: ['./step-vehicle-infos.component.css']
})
export class StepVehicleInfosComponent implements OnInit {

  get categoryList(): RefInterface[] {
    return this.lCategoryList;
  }

  @Input() parentForm: FormGroup;
  @Input() devMode: boolean;
  @Input() mode: string;

  private lCategoryList: RefInterface[];
  private countryList: CountryInterface[];

  vehicleInfosForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private subscriptionService: SubscriptionService,
              private languageService: LanguageService) {

  }

  ngOnInit() {

    this.initForm();

    this.languageService.getCurrentLanguage().subscribe(lang => {
      const selectedCategory = this.vehicleInfosForm.get('category').value;
      switch (lang) {
        case 'fr':
          this.lCategoryList = (frCategoryData as any).default;
          this.countryList = (frCountryListData as any).default;
          break;
        case 'en':
          this.lCategoryList = (enCategoryData as any).default;
          this.countryList = (enCountryListData as any).default;
          break;
      }

      if (selectedCategory) {
        this.vehicleInfosForm.get('category').setValue(this.lCategoryList.filter(item => item.code === selectedCategory.code)[0]);
      }
      this.vehicleInfosForm.get('destination').setValue(this.countryList.filter(item => item.code === 'GN')[0]);
    });

    this.subscriptionService.getModification().subscribe(modification => {
      if (this.mode === 'UPDATE' && modification != null && modification.vehicles != null) {
        this.vehicleInfosForm.get('brand').setValue(modification.vehicles[0].brand);
        this.vehicleInfosForm.get('registration').setValue(modification.vehicles[0].registration);
        this.vehicleInfosForm.get('category').setValue(
          this.lCategoryList.filter(item => item.code === modification.vehicles[0].category)[0]);
        this.vehicleInfosForm.get('category').disable();
        this.vehicleInfosForm.get('serialNumber').setValue(modification.vehicles[0].number);
        this.vehicleInfosForm.get('destination').setValue(
          this.countryList.filter(item => item.code === modification.vehicles[0].destination)[0]);
      }
    });

    this.parentForm.addControl('vehicleInfos', this.vehicleInfosForm);
  }

  initForm() {
    this.vehicleInfosForm = this.formBuilder.group({
      brand: [null, [Validators.required, Validators.maxLength(100)]],
      registration: [null, [Validators.required, Validators.maxLength(100)]],
      category: [null, Validators.required],
      serialNumber: [null, [Validators.required, Validators.maxLength(100)]],
      destination: [null, Validators.required]
    });
  }

  toUpperCase(key: string) {
    const value = this.vehicleInfosForm.get(key).value;
    this.vehicleInfosForm.get(key).setValue(value.toUpperCase());
  }

}
