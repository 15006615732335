import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {LanguageService} from '../../shared/services/language.service';
import * as config from '../../../assets/conf/config.json';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  lang: string;
  constructor(private http: HttpClient,
              private languageService: LanguageService) {
    this.languageService.getCurrentLanguage().subscribe(lang => this.lang = lang);

  }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getBaseUrl(): string {
    const configs = (config as any).default;
    const baseurl = configs[0].baseUrl + '/epass-api/api/';
    return baseurl;
  }

  get<T>(uri: string, token?: string): Observable<T> {
    let headers = this.httpOptions.headers;
    if (token) {
      headers = this.httpOptions.headers.append('X-GN-EPASS-TOKEN', token.toString());
    }
    headers = headers.set('Accept-Language', this.lang);
    return this.http.get<T>(this.getBaseUrl() + uri, {headers});
  }

  post<T>(uri: string, body, token?): Observable<T> {
    let headers = this.httpOptions.headers;
    if (token) {
      headers = this.httpOptions.headers.append('X-GN-EPASS-TOKEN', token.toString());
    }
    headers = headers.set('Accept-Language', this.lang);
    return this.http.post<T>(this.getBaseUrl() + uri, body, {headers});
  }

  put<T>(uri: string, body?, token?): Observable<T> {
    let headers = this.httpOptions.headers;
    if (token) {
      headers = this.httpOptions.headers.append('X-GN-EPASS-TOKEN', token.toString());
    }
    headers = headers.set('Accept-Language', this.lang);
    return this.http.put<T>(this.getBaseUrl() + uri, body, {headers});
  }

  delete<T>(uri: string, token?): Observable<T> {
    const headers = this.httpOptions.headers.set('Accept-Language', this.lang);
    return this.http.delete<T>(this.getBaseUrl() + uri, {headers});
  }


}
