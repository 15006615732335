<ng-container>
  <form [formGroup]="vehicleInfosForm" class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <div style="vertical-align: middle">
            <mat-icon>directions_car</mat-icon>&nbsp; <strong>{{'forms.vehicle.title'|translate}}</strong>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="container">
        <div class="row">
          <mat-form-field class="col col-md-4">
            <mat-label> {{'forms.vehicle.category'|translate}}</mat-label>
            <mat-select formControlName="category" required>
              <mat-option *ngFor="let category of categoryList" [value]="category">
                {{category.label}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="vehicleInfosForm.get('category').errors && vehicleInfosForm.get('category').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col col-md-4">
            <input matInput (input)="toUpperCase('brand')" placeholder="{{'forms.vehicle.brand'|translate}}" formControlName="brand" required>
            <mat-error
              *ngIf="vehicleInfosForm.get('brand').errors && vehicleInfosForm.get('brand').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="vehicleInfosForm.get('brand').errors && vehicleInfosForm.get('brand').errors.maxlength">
              {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col col-md-4">
            <input matInput (input)="toUpperCase('serialNumber')" placeholder="{{'forms.vehicle.serialNumber'|translate}}" formControlName="serialNumber" required>
            <mat-error
              *ngIf="vehicleInfosForm.get('serialNumber').errors && vehicleInfosForm.get('serialNumber').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="vehicleInfosForm.get('serialNumber').errors && vehicleInfosForm.get('serialNumber').errors.maxlength">
              {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col col-md-4">
            <input matInput (input)="toUpperCase('registration')" placeholder="{{'forms.vehicle.registration'|translate}}" formControlName="registration" required>
            <mat-error
              *ngIf="vehicleInfosForm.get('registration').errors && vehicleInfosForm.get('registration').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="vehicleInfosForm.get('registration').errors && vehicleInfosForm.get('registration').errors.maxlength">
              {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row ">
          <select-country [parent]="false" [organisation]="false" [mode]="mode" class="col col-md-4"
                          [parentForm]="vehicleInfosForm"
                          [countryControlName]="'destination'"
                          [disabled]="true"
                          [countrySelectLabel]="'forms.vehicle.destination'|translate"></select-country>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</ng-container>
