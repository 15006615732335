<ng-container>
  <form [formGroup]="paymentInfosForm" class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <div style="vertical-align: middle">
            <mat-icon>payment</mat-icon>&nbsp; <strong>{{'forms.payment.title'|translate}}</strong>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col col-md-4">
            <mat-label> {{'forms.payment.type'|translate}}</mat-label>
            <mat-select formControlName="type" required>
              <mat-option *ngFor="let type of typeList" [value]="type">
                {{type.label}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="paymentInfosForm.get('type').errors && paymentInfosForm.get('type').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col col-md-4">
            <input matInput placeholder="{{'forms.payment.source'|translate}}" type="tel"
                   [pattern]="phonePattern"
                   maxlength="15" formControlName="source" required>
            <mat-error
              *ngIf="paymentInfosForm.get('source').errors && paymentInfosForm.get('source').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="paymentInfosForm.get('source').errors && paymentInfosForm.get('source').errors.pattern && !paymentInfosForm.get('source').errors.required">
              {{'forms.errors.invalidFormat'| translate }}
            </mat-error>
            <mat-error
              *ngIf="paymentInfosForm.get('source').errors && paymentInfosForm.get('source').errors.maxlength">
              {{'forms.errors.maxLength'|translate}} 15 {{'forms.errors.chars'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col col-md-4">
            <input matInput placeholder="{{'forms.payment.number'|translate}}" formControlName="number"
                   maxlength="100" required>
            <mat-error
              *ngIf="paymentInfosForm.get('number').errors && paymentInfosForm.get('number').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col col-md-4">
            <input matInput placeholder="{{'forms.payment.amount'|translate}}" formControlName="amount">
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</ng-container>
