import {Component, ElementRef, isDevMode, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {PassService} from '../../shared/services/pass.service';
import {Auth} from '../../core/model/auth.interface';
import {RecaptchaAuth} from '../../core/model/recaptchaAuth.interface';
import {AuthenticationService} from '../../core/services/authentication.service';
import {MatDialog} from '@angular/material';
import {DialogConfirmComponent} from '../../shared/dialog-confirm/dialog-confirm.component';
import {SpinnerOverlayService} from '../../shared/services/spinner-overlay.service';
import {LanguageService} from '../../shared/services/language.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  @ViewChild('recaptcha', {static: true}) recaptchaElement: ElementRef;
  mailValidated = false;
  showCaptcha = true;
  siteKey = '6LddBsQUAAAAAGQuVEgOEPmlnVgtWcqpVVx3Jisd';
  devMode: boolean;
  lang: string;
  constructor(private formBuilder: FormBuilder,
              private passService: PassService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private dialog: MatDialog,
              private spinnerService: SpinnerOverlayService,
              private languageService: LanguageService
  ) {
  }

  ngOnInit() {
    this.devMode = isDevMode();
    this.loginForm = this.formBuilder.group({
      loginMail: [null, [Validators.required, Validators.maxLength(80), Validators.email]],
      code: null,
      captcha: [null, Validators.required]
    });
    this.languageService.getCurrentLanguage().subscribe(lang => {
      this.lang = lang;
    });
  }

  validate() {
    if (!this.mailValidated) {
      this.validateEmail();
    } else {
      this.validateCode();
    }
  }
  get language() {
    return this.lang;
  }

  validateEmail() {

    const auth: RecaptchaAuth = {
      username: this.loginForm.get('loginMail').value,
      recaptcha: this.loginForm.get('captcha').value,
    };
    this.spinnerService.show();
    this.passService.access(auth).subscribe((result: any) => {
      if (result.status === 'OK') {
        this.showCaptcha = false;
        this.mailValidated = true;
        this.loginForm.get('code').setValidators([Validators.required]);
        this.loginForm.get('captcha').setValidators([]);

        this.loginForm.get('code').updateValueAndValidity();
        this.loginForm.get('captcha').updateValueAndValidity();
        this.loginForm.updateValueAndValidity();
        this.spinnerService.hide();
      } else if (result.status === 'KO' && result.error === auth.username) {
        const validatedUser: Auth = {
          username: this.loginForm.get('loginMail').value,
          password: ''
        };
        this.authenticationService.login(validatedUser);
        this.spinnerService.hide();
        this.router.navigate(['/creation']);
      } else {
        this.spinnerService.hide();
        this.dialog.open(DialogConfirmComponent, {
          width: '500px',
          panelClass: 'my-dialog',
          data: {
            message: result.error
          }
        });
      }
    }, error => {
      const validatedUser: Auth = {
        username: this.loginForm.get('loginMail').value,
        password: ''
      };
      this.authenticationService.login(validatedUser);
      this.spinnerService.hide();
      this.router.navigate(['/creation']);
    });
  }

  handleReset() {

  }

  handleExpire() {

  }

  handleLoad() {

  }

  handleSuccess($event) {
    this.loginForm.patchValue({
      captcha: $event
    });
  }

  validateCode() {
    const auth: Auth = {
      username: this.loginForm.get('loginMail').value,
      password: this.loginForm.get('code').value,
    };
    this.spinnerService.show();
    this.passService.enable(auth).subscribe((result: any) => {
      if (result.status === 'OK') {
        this.authenticationService.login(auth);
        this.spinnerService.hide();
        this.router.navigate(['/creation']);
      } else {
        this.spinnerService.hide();
        this.dialog.open(DialogConfirmComponent, {
          width: '500px',
          panelClass: 'my-dialog',
          data: {
            message: result.error
          }
        });
      }
    });
  }

  goHome() {
    this.router.navigate(['']);
  }
}
