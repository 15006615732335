<ng-container class="container">
  <div class="container">
    <div class="recap-container" *ngIf="details">
      <div class="section-container" *ngIf="details.organisationInfos">
        <h4>{{'forms.organisationInfo.title'|translate}}</h4>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-4">
                <div *ngIf="details.organisationInfos.gender" class="info">
                  <strong>{{'forms.organisationInfo.gender'|translate}} </strong>:
                  {{details.organisationInfos.gender.label}}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div *ngIf="details.organisationInfos.lastName" class="info">
                  <strong>{{'forms.organisationInfo.lastName'|translate}} </strong>:
                  {{details.organisationInfos.lastName}}
                </div>
                <div *ngIf="details.organisationInfos && details.organisationInfos.birthDate" class="info">
                  <strong>{{'forms.infos.birthDay'|translate}} </strong>:
                  &nbsp; {{details.organisationInfos.birthDate|date:'dd/MM/yyyy'}}
                </div>
                <div *ngIf="details.organisationInfos.jobTitle" class="info">
                  <strong>{{'forms.organisationInfo.jobTitle'|translate}} </strong>:
                  {{details.organisationInfos.jobTitle}}
                </div>
                <div *ngIf="details.organisationInfos.siren" class="info">
                  <strong>{{'forms.organisationInfo.siren'|translate}} </strong>:
                  {{details.organisationInfos.siren}}
                </div>
              </div>
              <div class="col-sm-4">
                <div *ngIf="details.organisationInfos.firstName" class="info">
                  <strong>{{'forms.organisationInfo.firstName'|translate}} </strong>:
                  {{details.organisationInfos.firstName}}
                </div>
                <div *ngIf="details.organisationInfos && details.organisationInfos.birthCity" class="info">
                  <strong>{{'forms.infos.birthTown'|translate}} </strong>:
                  &nbsp; {{details.organisationInfos.birthCity}}
                </div>
                <div *ngIf="details.organisationInfos.type" class="info">
                  <strong>{{'forms.organisationInfo.type'|translate}} </strong>:
                  {{details.organisationInfos.type.label}}
                </div>
                <div *ngIf="details.organisationInfos.phone" class="info">
                  <strong>{{'forms.organisationInfo.phone'|translate}} </strong>:
                  &nbsp; {{details.organisationInfos.phone}}
                </div>
              </div>
              <div class="col-sm-4">
                <div *ngIf="details.organisationInfos && details.organisationInfos.birthCountry" class="info">
                  <strong>{{'forms.infos.birthCountry'|translate}} </strong>:
                  &nbsp; {{details.organisationInfos.birthCountry.name}}
                </div>
                <div *ngIf="details.organisationInfos.name" class="info">
                  <strong>{{'forms.organisationInfo.name'|translate}} </strong>:
                  &nbsp; {{details.organisationInfos.name}}
                </div>
                <div *ngIf="details.organisationInfos.email" class="info">
                  <strong>{{'forms.organisationInfo.email'|translate}} </strong>:
                  &nbsp; {{details.organisationInfos.email}}
                </div>
                <div *ngIf="details.organisationInfos.address" class="info">
                  <strong>{{'forms.address.title'|translate}} </strong>:
                  &nbsp; {{parseAddress(details.organisationInfos.address)}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-container" *ngIf="details.organisationInfos">
        <h4>{{'forms.contact.title'|translate}}</h4>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-6">
                <div *ngIf="details.personalInfos && details.organisationInfos && details.personalInfos.phone" class="info">
                  <strong>{{'forms.contact.phoneNumber'|translate}} </strong>:
                  {{details.personalInfos.phone}}
                </div>Informations du propriétaire du véhicule
              </div>
              <div class="col-sm-6">
                <div *ngIf="details.personalInfos && details.organisationInfos && details.personalInfos.email" class="info">
                  <strong>{{'forms.contact.email.title'|translate}} </strong>:
                  {{details.personalInfos.email}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-container" *ngIf="details.personalInfos && !details.organisationInfos">
        <h4>{{'forms.infos.title'|translate}}</h4>
        <div class="row">
          <div class="col-sm-5">
            <div *ngIf="details.personalInfos && details.personalInfos.lastName" class="info">
              <strong>{{'forms.infos.lastName'|translate}} </strong>:
              &nbsp; {{details.personalInfos.lastName}}
            </div>
            <div *ngIf="details.personalInfos && details.personalInfos.birthDate" class="info">
              <strong>{{'forms.infos.birthDay'|translate}} </strong>:
              &nbsp; {{details.personalInfos.birthDate|date:'dd/MM/yyyy'}}
            </div>
            <div *ngIf="details.personalInfos && details.personalInfos.state" class="info">
              <strong>{{'forms.infos.prefecture'|translate}} </strong>:
              &nbsp; {{details.personalInfos.state}}
            </div>
            <div *ngIf="details.personalInfos && details.personalInfos.nationality" class="info">
              <strong>{{'forms.infos.nationality'|translate}} </strong>:
              &nbsp; {{details.personalInfos.nationality.name}}
            </div>
            <div *ngIf="details.personalInfos && !details.organisationInfos && details.personalInfos.phone" class="info">
              <strong>{{'forms.contact.phoneNumber'|translate}} </strong>:
              {{details.personalInfos.phone}}
            </div>
            <div *ngIf="details.personalInfos && !details.organisationInfos && details.personalInfos.address" class="info">
              <strong>{{'forms.address.title'|translate}} </strong>:
              &nbsp; {{parseAddress(details.personalInfos.address)}}
            </div>
          </div>
          <div class="col-sm-5">
            <div *ngIf="details.personalInfos && details.personalInfos.firstName" class="info">
              <strong>{{'forms.infos.firstName'|translate}} </strong>:
              &nbsp; {{details.personalInfos.firstName}}
            </div>
            <div *ngIf="details.personalInfos && details.personalInfos.gender" class="info">
              <strong>{{'forms.infos.gender'|translate}} </strong>:
              &nbsp; {{details.personalInfos.gender.label}}
            </div>
            <div *ngIf="details.personalInfos && details.personalInfos.birthCountry" class="info">
              <strong>{{'forms.infos.birthCountry'|translate}} </strong>:
              &nbsp; {{details.personalInfos.birthCountry.name}}
            </div>
            <div *ngIf="details.personalInfos && !details.organisationInfos && details.personalInfos.email" class="info">
              <strong>{{'forms.contact.email.title'|translate}} </strong>:
              {{details.personalInfos.email}}
            </div>
            <div *ngIf="details.personalInfos && details.personalInfos.birthCity" class="info">
              <strong>{{'forms.infos.birthTown'|translate}} </strong>:
              &nbsp; {{details.personalInfos.birthCity}}
            </div>
          </div>
          <div class="col-sm-2">
            <div *ngIf="details.vouchersInfos && details.vouchersInfos.photo && details.vouchersInfos.photo.content"
                 style="width: 100%;">
              <img [src]="details.vouchersInfos.photo.content" width="140px" height="180px" class="image-id"/>
            </div>
          </div>
        </div>
      </div>
      <div class="section-container" *ngIf="details.driverInfos">
        <h4>{{'forms.driver.title'|translate}}</h4>
        <div class="row">
          <div class="col-sm-5">
            <div *ngIf="details.driverInfos.lastName" class="info">
              <strong>{{'forms.infos.lastName'|translate}} </strong>:
              {{details.driverInfos.lastName}}
            </div>
            <div *ngIf="details.driverInfos.birthDate" class="info">
              <strong>{{'forms.infos.birthDay'|translate}} </strong>:
              {{details.driverInfos.birthDate | date:'dd/MM/yyyy'}}
            </div>
            <div *ngIf="details.driverInfos.nationality" class="info">
              <strong>{{'forms.infos.nationality'|translate}} </strong>:
              &nbsp; {{details.driverInfos.nationality.name}}
            </div>
            <div *ngIf="details.driverInfos.birthCountry" class="info">
              <strong>{{'forms.infos.birthCountry'|translate}} </strong>:
              &nbsp; {{details.driverInfos.birthCountry.name}}
            </div>
          </div>
          <div class="col-sm-5">
            <div *ngIf="details.driverInfos.firstName" class="info">
              <strong>{{'forms.infos.firstName'|translate}} </strong>:
              {{details.driverInfos.firstName}}
            </div>
            <div *ngIf="details.driverInfos.gender" class="info">
              <strong>{{'forms.infos.gender'|translate}} </strong>:
              {{details.driverInfos.gender.label}}
            </div>
            <div *ngIf="details.driverInfos.birthCity" class="info">
              <strong>{{'forms.infos.birthTown'|translate}} </strong>:
              &nbsp; {{details.driverInfos.birthCity}}
            </div>
          </div>
          <div class="col-sm-2">
            <div *ngIf="details.vouchersInfos && details.vouchersInfos.photoDriver && details.vouchersInfos.photoDriver.content"
                 style="width: 100%;">
              <img [src]="details.vouchersInfos.photoDriver.content" width="140px" height="180px" class="image-id"/>
            </div>
          </div>
        </div>
      </div>
      <div class="section-container" *ngIf="details.vehicleInfos">
        <h4>{{'forms.vehicle.title'|translate}}</h4>
        <div class="row">
          <div class="col-sm-6">
            <div *ngIf="details.vehicleInfos.category" class="info">
              <strong>{{'forms.vehicle.category'|translate}} </strong>:
              &nbsp; {{details.vehicleInfos.category.label}}
            </div>
            <div *ngIf="details.vehicleInfos.brand" class="info">
              <strong>{{'forms.vehicle.brand'|translate}} </strong>:
              &nbsp; {{details.vehicleInfos.brand}}
            </div>
            <div *ngIf="details.vehicleInfos.serialNumber" class="info">
              <strong>{{'forms.vehicle.serialNumber'|translate}} </strong>:
              &nbsp; {{details.vehicleInfos.serialNumber}}
            </div>
          </div>
          <div class="col-sm-6">
            <div *ngIf="details.vehicleInfos.registration" class="info">
              <strong>{{'forms.vehicle.registration'|translate}} </strong>:
              &nbsp; {{details.vehicleInfos.registration}}
            </div>
            <div *ngIf="details.vehicleInfos.destination" class="info">
              <strong>{{'forms.vehicle.destination'|translate}} </strong>:
              &nbsp; {{details.vehicleInfos.destination.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="section-container" *ngIf="details.vouchersInfos">
        <h4>{{'forms.justificatory.title'|translate}}</h4>
        <div class="row">
          <div class="col-sm-12">
            <div class="container-personel-details" *ngIf="details.vouchersInfos.ownerIdCard">
              <div class="info">
                <strong>{{(details.vouchersInfos.ownerIdCard.code === 'SGN00028' ? 'forms.vouchers.id':'forms.vouchers.passport')|translate}} </strong>:&nbsp;
                <a (click)="openFile(details.vouchersInfos.ownerIdCard)">{{details.vouchersInfos.ownerIdCard.name}}</a>
              </div>
            </div>
            <div class="container-personel-details" *ngIf="details.vouchersInfos.driverIdCard">
              <div class="info">
                <strong>{{(details.vouchersInfos.driverIdCard.code === 'SGN00028' ? 'forms.vouchers.idDriver':'forms.vouchers.passport_driver')|translate}} </strong>:&nbsp;
                <a (click)="openFile(details.vouchersInfos.driverIdCard)">{{details.vouchersInfos.driverIdCard.name}}</a>
              </div>
            </div>
            <div class="container-personel-details" *ngIf="details.vouchersInfos.vehicleCertificate">
              <div class="info">
                <strong>{{'forms.vouchers.document'|translate}} </strong>:&nbsp;
                <a (click)="openFile(details.vouchersInfos.vehicleCertificate)">{{details.vouchersInfos.vehicleCertificate.name}}</a>
              </div>
            </div>
            <div class="container-personel-details" *ngIf="details.vouchersInfos.vehiclePass">
              <div class="info">
                <strong>{{'forms.vouchers.pass'|translate}} </strong>:&nbsp;
                <a (click)="openFile(details.vouchersInfos.vehiclePass)">{{details.vouchersInfos.vehiclePass.name}}</a>
              </div>
            </div>
            <div class="container-personel-details" *ngIf="details.vouchersInfos.kbis">
              <div class="info">
                <strong>{{'forms.vouchers.kbis'|translate}} </strong>:&nbsp;
                <a (click)="openFile(details.vouchersInfos.kbis)">{{details.vouchersInfos.kbis.name}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recap-container" *ngIf="additionnalSupports">
      <div class="section-container" *ngIf="additionnalSupports">
        <h4>{{'forms.justificatory.title'|translate}}</h4>
        <div class="row">
          <div class="col-sm-10">
            <div class="container-personel-details" *ngFor="let additionnalSupport of additionnalSupports">
              <div class="info">
                <strong>{{getLabel(additionnalSupport)}}</strong>:&nbsp;
                <a (click)="openFile(additionnalSupport)"> {{additionnalSupport.name}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
