<form [formGroup]="organisationForm" class="container">
  <div class="row">
    <mat-form-field class="col col-md-4">
      <mat-label>{{'forms.organisationInfo.gender'|translate}}</mat-label>
      <mat-select formControlName="gender" required>
        <mat-option *ngFor="let gender of genderList" [value]="gender">
          {{gender.label}}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="organisationForm.get('gender').errors && organisationForm.get('gender').errors.required">
        {{'forms.mandatory'|translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="col col-md-4">
      <input matInput (input)="toUpperCase('lastName')" placeholder="{{'forms.organisationInfo.lastName'|translate}}" formControlName="lastName" required>
      <mat-error
        *ngIf="organisationForm.get('lastName').errors && organisationForm.get('lastName').errors.required">
        {{'forms.mandatory'|translate}}
      </mat-error>
      <mat-error
        *ngIf="organisationForm.get('lastName').errors && organisationForm.get('lastName').errors.maxlength">
        {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col col-md-4">
      <input matInput (input)="toUpperCase('firstName')" placeholder="{{'forms.organisationInfo.firstName'|translate}}" formControlName="firstName" required>
      <mat-error
        *ngIf="organisationForm.get('firstName').errors && organisationForm.get('firstName').errors.required">
        {{'forms.mandatory'|translate}}
      </mat-error>
      <mat-error
        *ngIf="organisationForm.get('firstName').errors && organisationForm.get('firstName').errors.maxlength">
        {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="col col-md-4">
      <input matInput [matDatepicker]="picker" [max]="today" placeholder="{{'forms.infos.birthDay'|translate}}"
             formControlName="birthDate"
             required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error
        *ngIf="organisationForm.get('birthDate').errors && organisationForm.get('birthDate').errors.required
               && !organisationForm.get('birthDate').errors.matDatepickerParse
               && !organisationForm.get('birthDate').errors.matDatepickerMax">
        {{'forms.mandatory'|translate}}
      </mat-error>
      <mat-error
        *ngIf="organisationForm.get('birthDate').errors && organisationForm.get('birthDate').errors.matDatepickerParse">
        {{'forms.errors.dateFormat'|translate}}
      </mat-error>
      <mat-error
        *ngIf="organisationForm.get('birthDate').errors && organisationForm.get('birthDate').errors.matDatepickerMax">
        {{'forms.errors.dateInfToday'|translate}}
      </mat-error>
      <mat-error
        *ngIf="organisationForm.get('birthDate').errors && organisationForm.get('birthDate').errors.invalidAdult">
        {{'forms.errors.age'|translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col col-md-4">
      <input matInput (input)="toUpperCase('birthCity')" placeholder="{{'forms.infos.birthTown'|translate}}" formControlName="birthCity" required>
      <mat-error
        *ngIf="organisationForm.get('birthCity').errors && organisationForm.get('birthCity').errors.required">
        {{'forms.mandatory'|translate}}
      </mat-error>
    </mat-form-field>
    <select-country [parent]="false"[organisation]="false" class="col col-md-4"
                    [parentForm]="organisationForm"
                    [countryControlName]="'birthCountry'"
                    [countrySelectLabel]="'forms.infos.birthCountry'|translate"></select-country>
  </div>
  <div class="row">
    <mat-form-field class="col col-md-4">
      <input matInput (input)="toUpperCase('jobTitle')" placeholder="{{'forms.organisationInfo.jobTitle'|translate}}" formControlName="jobTitle" required>
      <mat-error
        *ngIf="organisationForm.get('jobTitle').errors && organisationForm.get('jobTitle').errors.required">
        {{'forms.mandatory'|translate}}
      </mat-error>
      <mat-error
        *ngIf="organisationForm.get('jobTitle').errors && organisationForm.get('jobTitle').errors.maxlength">
        {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col col-md-4">
      <mat-label>{{'forms.organisationInfo.type'|translate}}</mat-label>
      <mat-select formControlName="type" required>
        <mat-option *ngFor="let organisationType of organisationTypes" [value]="organisationType">
          {{organisationType.label}}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="organisationForm.get('type').errors && organisationForm.get('type').errors.required">
        {{'forms.mandatory'|translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col col-md-4">
      <input matInput (input)="toUpperCase('name')" placeholder="{{'forms.organisationInfo.name'|translate}}" formControlName="name" required>
      <mat-error
        *ngIf="organisationForm.get('name').errors && organisationForm.get('name').errors.required">
        {{'forms.mandatory'|translate}}
      </mat-error>
      <mat-error
        *ngIf="organisationForm.get('name').errors && organisationForm.get('name').errors.maxlength">
        {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="col col-md-4">
      <input matInput (input)="toUpperCase('siren')" placeholder="{{'forms.organisationInfo.siren'|translate}}" formControlName="siren">
      <mat-error
        *ngIf="organisationForm.get('siren').errors && organisationForm.get('siren').errors.required">
        {{'forms.mandatory'|translate}}
      </mat-error>
      <mat-error
        *ngIf="organisationForm.get('siren').errors && organisationForm.get('siren').errors.maxlength">
        {{'forms.errors.maxLength'|translate}} 50 {{'forms.errors.chars'|translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col col-md-4">
      <input matInput placeholder="{{'forms.organisationInfo.phone'|translate}}" type="tel"
             [pattern]="phonePattern"
             maxlength="15" formControlName="phone">
      <mat-error
        *ngIf="organisationForm.get('phone').errors && organisationForm.get('phone').errors.pattern">
        {{'forms.errors.invalidFormat'| translate }}
      </mat-error>
      <mat-error
        *ngIf="organisationForm.get('phone').errors && organisationForm.get('phone').errors.maxlength">
        {{'forms.errors.maxLength'|translate}} 15 {{'forms.errors.chars'|translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col col-md-4">
      <input matInput placeholder="{{'forms.organisationInfo.email'|translate}}" type="email" formControlName="email"
             maxlength="100">
      <mat-error *ngIf="organisationForm.get('email').errors && organisationForm.get('email').errors.email">
        {{'forms.contact.email.wrongFormat'|translate}}
      </mat-error>
    </mat-form-field>
  </div>
</form>
