<ng-container>
  <form [formGroup]="personalInfosForm" class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <div style="vertical-align: middle">
            <mat-icon>person_outline</mat-icon>&nbsp; <strong>{{'forms.infos.title'|translate}}</strong>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="container">
        <div class="row">
          <mat-slide-toggle formControlName="applicant">
            {{'forms.organisationInfo.applicantType'|translate}}
          </mat-slide-toggle>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="!isOrganisationRequired" style="margin-top: 2%;">
      <mat-card-content class="container">
        <div class="row">
          <mat-form-field class="col col-md-4">
            <input matInput (input)="toUpperCase('lastName')" placeholder="{{'forms.infos.lastName'|translate}}" formControlName="lastName" required>
            <mat-error
              *ngIf="personalInfosForm.get('lastName').errors && personalInfosForm.get('lastName').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="personalInfosForm.get('lastName').errors && personalInfosForm.get('lastName').errors.maxlength">
              {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col col-md-4">
            <input matInput (input)="toUpperCase('firstName')" placeholder="{{'forms.infos.firstName'|translate}}" formControlName="firstName" required>
            <mat-error
              *ngIf="personalInfosForm.get('firstName').errors && personalInfosForm.get('firstName').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="personalInfosForm.get('firstName').errors && personalInfosForm.get('firstName').errors.maxlength">
              {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col col-md-4">
            <input matInput [matDatepicker]="picker" [max]="today" placeholder="{{'forms.infos.birthDay'|translate}}"
                   formControlName="birthDate"
                   required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="personalInfosForm.get('birthDate').errors && personalInfosForm.get('birthDate').errors.required
               && !personalInfosForm.get('birthDate').errors.matDatepickerParse
               && !personalInfosForm.get('birthDate').errors.matDatepickerMax">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="personalInfosForm.get('birthDate').errors && personalInfosForm.get('birthDate').errors.matDatepickerParse">
              {{'forms.errors.dateFormat'|translate}}
            </mat-error>
            <mat-error
              *ngIf="personalInfosForm.get('birthDate').errors && personalInfosForm.get('birthDate').errors.matDatepickerMax">
              {{'forms.errors.dateInfToday'|translate}}
            </mat-error>
            <mat-error
              *ngIf="personalInfosForm.get('birthDate').errors && personalInfosForm.get('birthDate').errors.invalidAdult">
              {{'forms.errors.age'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col col-md-4">
            <mat-label> {{'forms.infos.gender'|translate}}</mat-label>
            <mat-select formControlName="gender" required>
              <mat-option *ngFor="let gender of genderList" [value]="gender">
                {{gender.label}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="personalInfosForm.get('gender').errors && personalInfosForm.get('gender').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row ">
          <mat-form-field class="col col-md-4">
            <input matInput (input)="toUpperCase('birthCity')" placeholder="{{'forms.infos.birthTown'|translate}}" formControlName="birthCity" required>
            <mat-error
              *ngIf="personalInfosForm.get('birthCity').errors && personalInfosForm.get('birthCity').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
          </mat-form-field>
          <select-country [parent]="false"[organisation]="false" [mode]="mode" class="col col-md-4"
                          [parentForm]="personalInfosForm"
                          [countryControlName]="'birthCountry'"
                          [countrySelectLabel]="'forms.infos.birthCountry'|translate"></select-country>
        </div>
        <div class="row justify-content-md-start">
          <select-country [parent]="false"[organisation]="false" [mode]="mode" class="col col-md-4"

                          [parentForm]="personalInfosForm"
                          [countryControlName]="'nationality'"
                          [countrySelectLabel]="'forms.infos.nationality'|translate"></select-country>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="isOrganisationRequired" style="margin-top: 2%;">
      <mat-card-header>
        <mat-card-title>
          <mat-icon>business</mat-icon> &nbsp; <strong>{{'forms.organisationInfo.title'|translate}}</strong>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <organisation-infos [modificationAllowed]="false" [parentFormGroup]="parentForm"></organisation-infos>
      </mat-card-content>
    </mat-card>
    <mat-card style="margin-top: 2%">
      <mat-card-header>
        <mat-card-title>
          <div style="vertical-align: middle">
            <mat-icon>assignment_outline</mat-icon>&nbsp; <strong>{{'forms.contact.title'|translate}}</strong>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field class="col col-md-4">
          <input matInput placeholder="{{'forms.contact.phoneNumber'|translate}}" type="tel"
                 [pattern]="phonePattern"
                 maxlength="15" formControlName="phone" required>
          <mat-error
            *ngIf="personalInfosForm.get('phone').errors && personalInfosForm.get('phone').errors.required">
            {{'forms.mandatory'|translate}}
          </mat-error>
          <mat-error
            *ngIf="personalInfosForm.get('phone').errors && personalInfosForm.get('phone').errors.pattern && !personalInfosForm.get('phone').errors.required">
            {{'forms.errors.invalidFormat'| translate }}
          </mat-error>
          <mat-error
            *ngIf="personalInfosForm.get('phone').errors && personalInfosForm.get('phone').errors.maxlength">
            {{'forms.errors.maxLength'|translate}} 15 {{'forms.errors.chars'|translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col col-md-4">
          <input matInput placeholder="{{'forms.contact.email.title'|translate}}" type="email" formControlName="email"
                 maxlength="100" required>
          <mat-error *ngIf="personalInfosForm.get('email').errors && personalInfosForm.get('email').errors.required"
                     [translate]="'forms.mandatory'"></mat-error>
          <mat-error *ngIf="personalInfosForm.get('email').errors && personalInfosForm.get('email').errors.email">
            {{'forms.contact.email.wrongFormat'|translate}}
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card style="margin-top: 2%">
      <mat-card-header>
        <mat-card-title>
          <div style="vertical-align: middle">
            <mat-icon>home</mat-icon>&nbsp; <strong>{{'forms.address.title'|translate}}</strong>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <gn-address [mode]="mode" [parentFormGroup]="personalInfosForm"></gn-address>
      </mat-card-content>
    </mat-card>
  </form>
</ng-container>
