<form [formGroup]="addressForm" class="container">
  <div class="row">
    <mat-form-field class="col col-md-4" style="width:400px;">
      <input matInput (input)="toUpperCase('streetName')" placeholder="{{'forms.address.streetName'|translate}}" formControlName="streetName" required>
      <mat-error *ngIf="addressForm.get('streetName').errors && addressForm.get('streetName').errors.required">Champs
        obligatoire
      </mat-error>
      <mat-error
        *ngIf="addressForm.get('streetName').errors && addressForm.get('streetName').errors.maxlength">
        {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col col-md-4" style="width:400px;">
      <input matInput (input)="toUpperCase('additional')" placeholder="{{'forms.address.adressComplement'|translate}}" formControlName="additional">
    </mat-form-field>
    <mat-error
      *ngIf="addressForm.get('additional').errors && addressForm.get('additional').errors.maxlength">
      {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
    </mat-error>
  </div>
  <div class="row">
    <!--<mat-form-field class="col col-md-4">
      <input matInput placeholder="{{'forms.address.postalCode'|translate}}" formControlName="zipCode"
             required>
      <mat-error *ngIf="addressForm.get('zipCode').errors && addressForm.get('zipCode').errors.required">Champs
        obligatoire
      </mat-error>
      <mat-error
        *ngIf="addressForm.get('zipCode').errors && addressForm.get('zipCode').errors.maxlength">
        {{'forms.errors.maxLength'|translate}} {{zipCodeMaxLength}}
      </mat-error>
      <mat-error
        *ngIf="addressForm.get('zipCode').errors && addressForm.get('zipCode').errors.pattern">
        {{'forms.errors.numericError'|translate}}
      </mat-error>
    </mat-form-field>-->
    <mat-form-field class="col col-md-4">
      <input matInput (input)="toUpperCase('town')" placeholder="{{'forms.address.town'|translate}}" maxlength="100" formControlName="town" required>
      <mat-error *ngIf="addressForm.get('town').errors && addressForm.get('town').errors.required">Champs obligatoire
      </mat-error>
      <mat-error
        *ngIf="addressForm.get('town').errors && addressForm.get('town').errors.maxlength">
        {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col col-md-4">
      <input matInput (input)="toUpperCase('state')" placeholder="{{'forms.address.state'|translate}}" formControlName="state">
    </mat-form-field>
  </div>
  <div class="row">
    <select-country class="col col-md-4"
                    [parentForm]="addressForm"
                    [mode]="mode"
                    [countryControlName]="'country'"
                    [countrySelectLabel]="'forms.address.country'|translate"></select-country>
  </div>
</form>
