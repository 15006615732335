import {NgModule} from '@angular/core';
import {CommonModule, JsonPipe} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';

import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule, MatMenuModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSelectModule,
  MatStepperModule
} from "@angular/material";
import {AddressComponent} from './address/address.component';
import {BrowserModule} from "@angular/platform-browser";
import {ImageCropperModule} from "ngx-image-cropper";
import {DialogConfirmComponent} from "./dialog-confirm/dialog-confirm.component";
import {NgxCaptchaModule} from "ngx-captcha";
import {FileSizePipe} from "./pipes/filesize.pipe";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import { SelectCountryComponent } from './select-country/select-country.component';
import {TranslateModule} from "@ngx-translate/core";
import {BsDropdownModule} from "ngx-bootstrap";
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    BrowserModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ImageCropperModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    NgxCaptchaModule,
    NgxMatSelectSearchModule,
    TranslateModule,
    BsDropdownModule,
    MatMenuModule,
    MatButtonModule
  ],
  declarations: [HeaderComponent, FooterComponent, AddressComponent, DialogConfirmComponent, FileSizePipe, SelectCountryComponent, SpinnerOverlayComponent, SpinnerComponent],
  exports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatStepperModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    AddressComponent,
    ImageCropperModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    NgxCaptchaModule,
    NgxMatSelectSearchModule,
    SelectCountryComponent,
    TranslateModule,
    BsDropdownModule,
    MatMenuModule
  ],
  providers: [JsonPipe]
})
export class SharedModule {
}



