import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  homeForm: FormGroup;
  private validateFormFail: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
              private router: Router) {
  }

  ngOnInit() {
    this.homeForm = this.formBuilder.group({
      accept: [null, Validators.required]
    });
  }

  validate() {

    if (this.homeForm.valid) {
      this.router.navigate(['/verification']);
    } else {
      this.validateFormFail.emit();
    }
  }

  openFile() {
    window.open('assets/docs/cgu.pdf', '_blank');
  }

}


