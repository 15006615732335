import {Injectable} from '@angular/core';
import {CoreService} from '../../core/services/core.service';
import {RecaptchaAuth} from '../../core/model/recaptchaAuth.interface';
import {Auth} from '../../core/model/auth.interface';
import {Subscription} from '../../core/model/subscription.interface';
import {Resource} from '../../core/model/resource.interface';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PassService {

  constructor(private client: CoreService, private http: HttpClient) {
  }

  access(recaptchaAuth: RecaptchaAuth) {
    return this.client.post('access', recaptchaAuth);
  }

  enable(auth: Auth) {
    return this.client.put('access/enable', auth);
  }

  verify(auth: Auth) {
    return this.client.put('access/verify', auth);
  }

  createPass(subscription): Observable<any> {
    // console.log(JSON.stringify(subscription));
    return this.client.post('pass', subscription);
  }

  updatePass(subscription: Subscription, reference, token) {
    return this.client.put('pass/' + reference + '/forms', subscription, token);
  }

  updatePassResources(reference, resources: Resource[], token) {
    return this.client.post('pass/' + reference + '/supports', resources, token);
  }

  getUpdatePassRecord(email, reference, token) {
    return this.client.get('pass/' + reference + '/corrections?email=' + email, token);
  }

  getPassRecord(reference, email, registration) {
    return this.client.get('pass/' + reference + '?email=' + email + '&registration=' + registration);
  }

  resendCode(reference, email) {
    return this.client.post('pass/' + reference + '/links/accounts/' + email + '/', {});
  }

  getAllSupportCode() {
    return this.http.get('https://api.csm.stg.e-kaidi.net/cases-api/api/supports/locale/fr');
  }
}
