import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DataInputModal} from "../../interfaces/DataInputModal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DateValidator} from "../../../../core/validators/date.validator";
import {AdditionnalSupport} from "../../interfaces/AdditionnalSupport";
import {Agent} from "../../interfaces/Agent";
import {FileSizePipe} from "../../../../shared/pipes/filesize.pipe";

@Component({
  selector: 'dialog-modification',
  templateUrl: './dialog-modification.component.html',
  styleUrls: ['./dialog-modification.component.css']
})
export class DialogModificationComponent implements OnInit {

  modalForm: FormGroup;
  additionnalSupport: AdditionnalSupport;
  agents: Agent[] = [];
  fileName: string;
  private fileContent: File;

  constructor(
    private filePipe: FileSizePipe,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogModificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataInputModal
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.modalForm = this.formBuilder.group({
      agent: [null, Validators.required],
      file: [null, Validators.required]
    });
    this.additionnalSupport = this.data.addiSupport;
    this.agents = this.data.agents;
    this.fileName = this.additionnalSupport.name;
  }

  closeDialog() {
    if (this.modalForm.invalid) {
      this.modalForm.markAllAsTouched();
    } else {
      // addit.file = this.modalForm.get('file').vale;
      this.data.addiSupport.haveValidFile = true;
      this.data.addiSupport.content = this.fileContent;
      const agentValue: Agent = this.modalForm.get('agent').value;
      // console.log('agentValue',agentValue);
      this.data.addiSupport.typeperson = agentValue.type;
      this.data.addiSupport.referenceperson = agentValue.reference;
      this.data.addiSupport.fileName = this.fileName;
      this.dialogRef.close({
        additionnalSupport: this.data.addiSupport
      });
    }
  }

  fileChangeEvent(event) {
    const input = event.target;
    this.fileName = input.files[0].name;
    this.fileContent = input.files[0];
  }
}
