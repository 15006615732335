import {Component, isDevMode, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  devMode: boolean;
  toMail = 'visa.consulat@ambaguinee.org';
  siteKey = '6LddBsQUAAAAAGQuVEgOEPmlnVgtWcqpVVx3Jisd';
  ngOnInit() {
    this.devMode = isDevMode();
    this.contactForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      content: [null, [Validators.required, Validators.maxLength(255)]],
      recordNumber: [null, [Validators.required]],
      mailObject: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
    });
  }


  handleSuccess($event) {
    this.contactForm.patchValue({
      captcha: $event
    });
  }

  handleReset() {

  }

  handleExpire() {

  }

  handleLoad() {

  }
}
