import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RefInterface} from '../../../../core/model/ref.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubscriptionService} from '../../subscription.service';
import {LanguageService} from '../../../../shared/services/language.service';
import * as frGenderData from '../../../../shared/data/fr-constants/genders.json';
import * as frCountryListData from '../../../../shared/data/fr-constants/countries.json';
import * as enGenderData from '../../../../shared/data/en-constants/genders.json';
import * as enCountryListData from '../../../../shared/data/en-constants/countries.json';
import {DriverService} from '../../../../shared/services/driver.service';
import {FrenchDateAdapter} from '../../../../core/adapters/french-date.adapter';
import {DateValidator} from '../../../../core/validators/date.validator';
import {CountryInterface} from '../../../../core/model/country.interface';
import {Subscription} from '../../../../core/model/subscription.interface';

@Component({
  selector: 'step-driver-infos',
  templateUrl: './step-driver-infos.component.html',
  styleUrls: ['./step-driver-infos.component.css']
})
export class StepDriverInfosComponent implements OnInit {

  get genderList(): RefInterface[] {
    return this.lGenderList;
  }

  @Input() parentForm: FormGroup;
  @Input() devMode: boolean;
  @Input() mode: string;

  private lGenderList: RefInterface[];
  private countryList: CountryInterface[];

  driverInfosForm: FormGroup;

  isDisabled = false;
  isManageOwnerDriver = true;

  today = new Date();

  constructor(private formBuilder: FormBuilder,
              private subscriptionService: SubscriptionService,
              private driverService: DriverService,
              private dateService: FrenchDateAdapter,
              private languageService: LanguageService,
              private cdRef: ChangeDetectorRef) {

  }

  ngOnInit() {

    this.initForm();

    this.languageService.getCurrentLanguage().subscribe(lang => {
      const selectedGender = this.driverInfosForm.get('gender').value;
      switch (lang) {
        case 'fr':
          this.lGenderList = (frGenderData as any).default;
          this.countryList = (frCountryListData as any).default;
          break;
        case 'en':
          this.lGenderList = (enGenderData as any).default;
          this.countryList = (enCountryListData as any).default;
          break;
      }

      if (selectedGender) {
        this.driverInfosForm.get('gender').setValue(this.lGenderList.filter(item => item.code === selectedGender.code)[0]);
      }
    });

    this.driverService.isManageOwnerDriverRequired().subscribe(required => {
      this.isManageOwnerDriver = required;
      if (required == false) {
        this.isDisabled = false;
        this.driverInfosForm.get('isOwner').setValue(false);
      }
    });

    this.subscriptionService.getModification().subscribe(modification => {
      if (this.mode === 'UPDATE') {
        this.updateDriver(modification);
      }
    });

    this.parentForm.addControl('driverInfos', this.driverInfosForm);
  }

  initForm() {
    this.driverInfosForm = this.formBuilder.group({
      isOwner: false,
      lastName: [null, [Validators.required, Validators.maxLength(100)]],
      firstName: [null, [Validators.required, Validators.maxLength(100)]],
      gender: [null, Validators.required],
      birthDate: [null, [Validators.required, DateValidator.adult]],
      birthCountry: [null, Validators.required],
      birthCity: [null, Validators.required],
      nationality: [null, Validators.required]
    });
  }

  slideToggleChange(event: any) {
    if (event && event.checked) {
      this.isDisabled = event.checked;
      this.driverInfosForm.get('lastName').disable();
      this.driverInfosForm.get('firstName').disable();
      this.driverInfosForm.get('birthDate').disable();
      this.driverInfosForm.get('birthCity').disable();
      this.driverService.requireDriverId(false);
      this.driverInfosForm.patchValue({
        lastName: this.parentForm.get('personalInfos').get('lastName').value,
        firstName: this.parentForm.get('personalInfos').get('firstName').value,
        gender: this.parentForm.get('personalInfos').get('gender').value,
        birthDate: this.parentForm.get('personalInfos').get('birthDate').value,
        birthCountry: this.parentForm.get('personalInfos').get('birthCountry').value,
        birthCity: this.parentForm.get('personalInfos').get('birthCity').value,
        nationality: this.parentForm.get('personalInfos').get('nationality').value
      });
    } else {
      this.isDisabled = false;
      this.initForm();
      this.driverInfosForm.get('lastName').enable();
      this.driverInfosForm.get('firstName').enable();
      this.driverInfosForm.get('birthDate').enable();
      this.driverInfosForm.get('birthCity').enable();
      this.driverService.requireDriverId(true);
    }
    this.parentForm.setControl('driverInfos', this.driverInfosForm);
  }

  toUpperCase(key: string) {
    const value = this.driverInfosForm.get(key).value;
    this.driverInfosForm.get(key).setValue(value.toUpperCase());
  }

  private updateDriver(modification: Subscription) {
    if (modification != null) {
      if (modification.vehicles != null && modification.vehicles[0].driver != null) {
        this.driverInfosForm.get('lastName').setValue(modification.vehicles[0].driver.lastName);
        this.driverInfosForm.get('firstName').setValue(modification.vehicles[0].driver.firstName);
        this.driverInfosForm.get('gender').setValue(
          this.lGenderList.filter(item => item.code === modification.vehicles[0].driver.gender)[0]);
        this.driverInfosForm.get('birthDate').setValue(this.dateService.parse(modification.vehicles[0].driver.birthInfo.day));
        this.driverInfosForm.get('birthCountry').setValue(
          this.countryList.filter(item => item.code === modification.vehicles[0].driver.birthInfo.country)[0]);
        this.driverInfosForm.get('birthCity').setValue(modification.vehicles[0].driver.birthInfo.city);
        this.driverInfosForm.get('nationality').setValue(
          this.countryList.filter(item => item.code === modification.vehicles[0].driver.nationality)[0]);
      } else {
        this.driverInfosForm.get('lastName').setValue(modification.applicant.lastName);
        this.driverInfosForm.get('firstName').setValue(modification.applicant.firstName);
        this.driverInfosForm.get('gender').setValue(
          this.lGenderList.filter(item => item.code === modification.applicant.gender)[0]);
        this.driverInfosForm.get('birthDate').setValue(this.dateService.parse(modification.applicant.birthInfo.day));
        this.driverInfosForm.get('birthCountry').setValue(
          this.countryList.filter(item => item.code === modification.applicant.birthInfo.country)[0]);
        this.driverInfosForm.get('birthCity').setValue(modification.applicant.birthInfo.city);
        this.driverInfosForm.get('nationality').setValue(
          this.countryList.filter(item => item.code === modification.applicant.nationality)[0]);
        this.driverInfosForm.get('isOwner').setValue(true);
        const event = {checked: true};
        this.slideToggleChange(event);
      }
    }
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
}
