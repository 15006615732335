import {Driver} from './driver.interface';
import {Vehicule} from './vehicule.interface';

export class Vehicles {
  driver: Driver;
  vehicle: Vehicule;
  origin: string;
  destination: string;
  brand?: string;
  category?: string;
  number?: string;
  registration?: string;
}
