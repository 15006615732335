import { NgModule, Optional, SkipSelf } from '@angular/core';

import { CoreRoutingModule } from './modules/core-routing/core-routing.module';
import {HttpClientModule} from "@angular/common/http";
import {Error} from "tslint/lib/error";

@NgModule({
  imports: [HttpClientModule],
  declarations: [],
  exports: [CoreRoutingModule]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
