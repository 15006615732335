<button class="close" mat-button (click)="close()">X</button>
<div mat-dialog-title>
  <h5 *ngIf="header"><strong>{{header}}</strong></h5>
</div>
<div mat-dialog-content>
  <form [formGroup]="form" class="container">
    <div class="row">
      <mat-form-field class="col col-sm-6">
        <input matInput placeholder="{{'forms.infos.email'|translate}}" formControlName="email" maxlength="100"
               required>
        <mat-error *ngIf="form.get('email').errors && form.get('email').errors.required">
          {{'forms.mandatory'|translate}}
        </mat-error>
        <mat-error *ngIf="form.get('email').errors && form.get('email').errors.email">
          {{'login.wrongFormat'|translate}}
        </mat-error>
        <mat-error
          *ngIf="form.get('email').errors && form.get('email').errors.maxlength">
          {{'forms.errors.maxLength'|translate}} 80 {{'forms.errors.chars'|translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col col-sm-6">
        <input matInput placeholder="{{'forms.consultation.recordNumber'|translate}}" maxlength="50"
               formControlName="reference" required>
        <mat-error
          *ngIf="form.get('reference').errors && form.get('reference').errors.required">
          {{'forms.mandatory'|translate}}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions style="width: 100%;" class="row justify-content-end mt-3">
  <button class="mat-raised-button submit-button mb-2" (click)="closeDialog()">{{'forms.modification.receive'|translate}}
  </button>
</div>
