import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/index';
import {Observable} from 'rxjs/internal/Observable';
import {LanguageService} from '../../shared/services/language.service';
import * as frGenderData from '../../shared/data/fr-constants/genders.json';
import * as enGenderData from '../../shared/data/en-constants/genders.json';
import * as frCountries from '../../shared/data/fr-constants/countries.json';
import * as enCountries from '../../shared/data/en-constants/countries.json';
import * as frGenderListData from '../../shared/data/fr-constants/genderList.json';
import * as enGenderListData from '../../shared/data/en-constants/genderList.json';
import {RefInterface} from '../../core/model/ref.interface';
import {CountryInterface} from '../../core/model/country.interface';
import {Resource} from '../../core/model/resource.interface';
import {FormArray, FormGroup} from '@angular/forms';
import {Address} from '../../core/model/address.interface';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {PassService} from "../../shared/services/pass.service";

@Injectable({
  providedIn: 'root'
})
export class ModificationService {
  private supportCode: any;
  private genderList: RefInterface[];
  private genders: RefInterface[];
  private countryList: CountryInterface[];
  private additionnalSupports = new BehaviorSubject([]);

  constructor(private languageService: LanguageService,
              private translate: TranslateService,
              private datePipe: DatePipe,
              private passService: PassService) {
    this.languageService.getCurrentLanguage().subscribe(lang => {
      switch (lang) {
        case 'fr':
          this.genderList = (frGenderData as any).default;
          this.countryList = [...(frCountries as any).default];
          this.genders = (frGenderListData as any).default;

          break;
        case 'en':
          this.genderList = (enGenderData as any).default;
          this.countryList = [...(enCountries as any).default];
          this.genders = (enGenderListData as any).default;
          break;
      }
    });
    this.getSupportCode();
  }

  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.includes('/'))) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    } else if ((typeof value === 'string') && value === '') {
      return new Date();
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  setAdditionnalSupports(additionnalSupports) {
    this.additionnalSupports.next(additionnalSupports);
  }


  getAdditionnalSupports(): Observable<any[]> {
    return this.additionnalSupports.asObservable();
  }

  mapResources(voucherForm: FormArray): Resource[] {
    const resources = [];
    for (const voucher of voucherForm.controls) {
      resources.push(voucher.value);
    }
    return resources;
  }

  mapAddress(form: FormGroup): Address {
    const address = form.value;
    return {
      city: address.city ? address.city : null,
      state: address.state ? address.state : null,
      /*postBox: address.postBox ? address.postBox : null,
      zipCode: address.zipCode ? address.zipCode : null,*/
      country: address.country ? address.country.code : null,
      streetName: address.streetName ? address.streetName : null,
      additional: address.additional ? address.additional : null
    };
  }

  getSupportCode() {
    this.passService.getAllSupportCode().subscribe(response => {
      if (response != null) {
        this.supportCode = response["data"];
      }
    })
  }

  getLabel(additionnalSupport: Resource) {
    const object = this.supportCode.find(obj => obj.code === additionnalSupport.code);
    return object.name;
  }

}
