<div class="home-container container bg-white">
  <div class="welcome-container">
    <h2 style="padding: 0 10%"> {{'home.welcome'|translate}}</h2>
    <p>{{'home.homeDescription'|translate}}
    </p>
  </div>
  <div class="image-container row">
    <img class="col col-md-auto" width="220" height="312" src="assets/images/laissez-passer.jpg"/>
    <strong class="col col-md-auto "
            style="padding-left: 5%"><em>{{'home.visaDescriptionPart1'|translate}}</em></strong>
  </div>
  <br/>
  <div class="section-container">
    <h3>{{'home.typeVehicule.title'|translate}}</h3>
    <ul>
      <li>{{'home.typeVehicule.stepOne'|translate}}</li>
      <li>{{'home.typeVehicule.stepTwo'|translate}}</li>
      <li>{{'home.typeVehicule.stepThree'|translate}}</li>
      <li>{{'home.typeVehicule.stepFour'|translate}}</li>
      <li>{{'home.typeVehicule.stepFive'|translate}}</li>
      <li>{{'home.typeVehicule.stepSix'|translate}}</li>
      <li>{{'home.typeVehicule.stepSept'|translate}}
      </li>
    </ul>
  </div>
  <div class="section-container">
    <h3>{{'home.procedure.title'|translate}}</h3>
    <ul>
      <li>{{'home.procedure.stepOne'|translate}}</li>
      <li>{{'home.procedure.stepTwo'|translate}}</li>
      <li>{{'home.procedure.stepThree'|translate}}
      </li>
    </ul>
  </div>
  <div class="section-container">
    <h3>{{'home.delay.title'|translate}}</h3>
    <p>{{'home.delay.description'|translate}}</p>
  </div>
  <div class="section-container">
    <h3>{{'home.pricing.title'|translate}}</h3>
    <ul>
      <li>{{'home.pricing.pricingOne'|translate}}</li>
      <li>{{'home.pricing.pricingTwo'|translate}}</li>
      <li>{{'home.pricing.pricingThree'|translate}}</li>
      <li>{{'home.pricing.pricingFour'|translate}}</li>
      <li>{{'home.pricing.pricingFive'|translate}}</li>
      <li>{{'home.pricing.pricingSix'|translate}}</li>
      <li>{{'home.pricing.pricingSeven'|translate}}</li>
      <li>{{'home.pricing.description'|translate}}</li>
    </ul>
  </div>
  <div class="section-container">
    <h3>{{'home.delivry.title'|translate}}</h3>
    <p>{{'home.delivry.types'|translate}}</p>
    <ul>
      <li>{{'home.delivry.retrieval'|translate}}</li>
      <li>{{'home.delivry.mail'|translate}}</li>
    </ul>
  </div>
  <div class="section-container">
    <h3>{{'home.rules.title'|translate}}</h3>
    <ul>
      <li>
        {{'home.rules.firstRule'|translate}}
      </li>
      <li>
        {{'home.rules.secondRule'|translate}}
      </li>
      <li>
        {{'home.rules.thirdRule'|translate}}
      </li>
    </ul>
  </div>
  <div>
    <div class="checkbox ">
      <form [formGroup]="homeForm">
        <mat-checkbox formControlName="accept" class="row" required>
          <span class="col-md-6 pr-0 pl-0 small-text">{{'home.agreement'|translate}} </span>
          <span class="col ol-md-6 pl-0 small-text componenet-only-in-desktop"><a href="#"
                                            (click)="openFile()">{{'home.generalConditions'|translate}}</a></span>
          <div class="col ol-md-6 pl-0 small-text componenet-only-in-mobile"><br/><a href="#"
                                            (click)="openFile()">{{'home.generalConditions'|translate}}</a></div>
        </mat-checkbox>
        <div class="submit">
          <button [disabled]="homeForm.invalid"
                  class="mat-raised-button submit-button" style="margin-bottom: 3%;margin-top: 1%" (click)="validate()"
                  mat-button>Continue
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
