<ng-container>
  <form [formGroup]="driverInfosForm" class="container">
    <mat-card *ngIf="isManageOwnerDriver">
      <mat-card-header>
        <mat-card-title>
          <div style="vertical-align: middle">
            <mat-icon>person_outline</mat-icon>&nbsp; <strong>{{'forms.driver.title'|translate}}</strong>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="container">
        <div class="row">
          <mat-slide-toggle (change)="slideToggleChange($event)" formControlName="isOwner">
            {{'forms.driver.isOwner'|translate}}
          </mat-slide-toggle>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card style="margin-top: 2%">
      <mat-card-header>
        <mat-card-title>
          <div style="vertical-align: middle">
            <mat-icon>assignment_ind</mat-icon>&nbsp; <strong>{{'forms.driver.infos'|translate}}</strong>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col col-md-4">
            <input matInput (input)="toUpperCase('lastName')" placeholder="{{'forms.infos.lastName'|translate}}" formControlName="lastName" required>
            <mat-error
              *ngIf="driverInfosForm.get('lastName').errors && driverInfosForm.get('lastName').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="driverInfosForm.get('lastName').errors && driverInfosForm.get('lastName').errors.maxlength">
              {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col col-md-4">
            <input matInput (input)="toUpperCase('firstName')" placeholder="{{'forms.infos.firstName'|translate}}" formControlName="firstName" required>
            <mat-error
              *ngIf="driverInfosForm.get('firstName').errors && driverInfosForm.get('firstName').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="driverInfosForm.get('firstName').errors && driverInfosForm.get('firstName').errors.maxlength">
              {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col col-md-4">
            <input matInput [matDatepicker]="picker" [max]="today" placeholder="{{'forms.infos.birthDay'|translate}}"
                   formControlName="birthDate"
                   required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="driverInfosForm.get('birthDate').errors && driverInfosForm.get('birthDate').errors.required
               && !driverInfosForm.get('birthDate').errors.matDatepickerParse
               && !driverInfosForm.get('birthDate').errors.matDatepickerMax">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="driverInfosForm.get('birthDate').errors && driverInfosForm.get('birthDate').errors.matDatepickerParse">
              {{'forms.errors.dateFormat'|translate}}
            </mat-error>
            <mat-error
              *ngIf="driverInfosForm.get('birthDate').errors && driverInfosForm.get('birthDate').errors.matDatepickerMax">
              {{'forms.errors.dateInfToday'|translate}}
            </mat-error>
            <mat-error
              *ngIf="driverInfosForm.get('birthDate').errors && driverInfosForm.get('birthDate').errors.invalidAdult">
              {{'forms.errors.age'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col col-md-4">
            <mat-label> {{'forms.infos.gender'|translate}}</mat-label>
            <mat-select [disabled]="isDisabled" formControlName="gender" required>
              <mat-option *ngFor="let gender of genderList" [value]="gender">
                {{gender.label}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="driverInfosForm.get('gender').errors && driverInfosForm.get('gender').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row ">
          <mat-form-field class="col col-md-4">
            <input matInput (input)="toUpperCase('birthCity')" placeholder="{{'forms.infos.birthTown'|translate}}" formControlName="birthCity" required>
            <mat-error
              *ngIf="driverInfosForm.get('birthCity').errors && driverInfosForm.get('birthCity').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
          </mat-form-field>
          <select-country [parent]="false" [organisation]="false" [mode]="mode" class="col col-md-4"
                          [disabled]="isDisabled"
                          [parentForm]="driverInfosForm"
                          [countryControlName]="'birthCountry'"
                          [countrySelectLabel]="'forms.infos.birthCountry'|translate"></select-country>
        </div>
        <div class="row justify-content-md-start">
          <select-country [parent]="false" [organisation]="false" [mode]="mode" class="col col-md-4"
                          [disabled]="isDisabled"
                          [parentForm]="driverInfosForm"
                          [countryControlName]="'nationality'"
                          [countrySelectLabel]="'forms.infos.nationality'|translate"></select-country>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</ng-container>
