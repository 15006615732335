<div class="dialog-crop-container">
  <h2 mat-dialog-title>{{'forms.vouchers.cropperTitle'|translate}}</h2>
  <em style="font-size: 0.8em;text-align: center"> {{'forms.vouchers.cropperMessage'|translate}}
  </em>
  <mat-dialog-content class="dialog-content">
    <div *ngIf="show" class="cropper-container">
      <ng-image-cropper src="{{imageSource}}" (croppedImageEvent)="croppedImageHandler($event)"></ng-image-cropper>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="d-flex justify-content-between">
      <button mat-button class="mat-raised-button bg-primary p-2" (click)="closeDialog(false)">Valider
      </button>
      <button style="margin-left: 50px" mat-button class="mat-raised-button bg-danger p-2" (click)="closeDialog(true)">
        Annuler
      </button>
    </div>
  </mat-dialog-actions>

</div>
