import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Observable} from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class CompanyApplicantService {

  private required = new BehaviorSubject(false);

  constructor() {
  }

  requireCompanyVouchers(required: boolean) {
    this.required.next(required);
  }


  isCompanyVouchersRequired(): Observable<boolean> {
    return this.required.asObservable();
  }

}
