<ng-container>
  <form [formGroup]="voucherForm" class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <div class="d-flex flex-row">
            <div class="p-2">
              <mat-icon>local_post_office</mat-icon>
            </div>
            <div class="p-2">
              &nbsp; <strong>Les justificatifs</strong>
            </div>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="col" *ngIf="isCompanyVouchersRequired">
          <div class="custom-file overflow-hidden rounded-pill col-md-12 mt-3">
            <input id="kbis" class="custom-file-input rounded-pill" type="file"
                   accept="image/jpeg, image/png, image/bmp, application/pdf"
                   (change)="fileChangeEvent($event, 'CERTIFICATE','kbis')"
            />
            <label class="custom-file-label rounded-pill" for="kbis" data-browse="Parcourir">
              {{'forms.vouchers.kbis'|translate}}
              {{kbisName? ('forms.vouchers.choosen'|translate) +kbisName: ''}}</label>
          </div>
        </div>
        <div class="col" *ngIf="!isCompanyVouchersRequired">
          <mat-form-field class="p-2 mt-3" style="width: 80%">
            <mat-label>{{'forms.vouchers.typeOwner'|translate}}</mat-label>
            <mat-select (selectionChange)="selectVoucherType($event, 'owner')" formControlName="voucherTypeOwner" required>
              <mat-option *ngFor="let voucherType of lVoucherTypes" [value]="voucherType.code">
                {{voucherType.label}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="voucherForm.get('voucherTypeOwner').invalid && voucherForm.get('voucherTypeOwner').touched">
              {{'forms.mandatory'|translate}}
            </mat-error>
          </mat-form-field>
          <div *ngIf="showId" class="custom-file overflow-hidden rounded-pill col-md-12 mt-3">
            <input id="ownerId" class="custom-file-input rounded-pill" type="file"
                   accept="image/jpeg, image/png, application/pdf"
                   (change)="fileChangeEvent($event, 'ID','ownerIdCard')"
                   required />
            <label class="custom-file-label rounded-pill" for="ownerId" data-browse="Parcourir">
              {{'forms.vouchers.id'|translate}}
              {{ownerIdCardName? ('forms.vouchers.choosen'|translate) + ownerIdCardName: ' *'}}</label>
          </div>
          <div
            *ngIf="showId && voucherForm.get('ownerIdCard').invalid && voucherForm.get('ownerIdCard').touched"
            class="error-message overflow-hidden rounded-pill col-md-12 ">
            {{'forms.mandatory'|translate}}
          </div>
          <div *ngIf="showPassport" class="custom-file overflow-hidden rounded-pill col-md-12 mt-3">
            <input id="ownerPassport" class="custom-file-input rounded-pill" type="file"
                   accept="image/jpeg, image/png, application/pdf"
                   (change)="fileChangeEvent($event, 'PASSPORT','ownerPassport')"
                   required />
            <label class="custom-file-label rounded-pill" for="ownerPassport" data-browse="Parcourir">
              {{'forms.vouchers.passport'|translate}}
              {{ownerPassportName? ('forms.vouchers.choosen'|translate) + ownerPassportName: ' *'}}</label>
          </div>
          <div
            *ngIf="showPassport && voucherForm.get('ownerIdCard').invalid && voucherForm.get('ownerIdCard').touched"
            class="error-message overflow-hidden rounded-pill col-md-12 ">
            {{'forms.mandatory'|translate}}
          </div>
          <div class="custom-file overflow-hidden rounded-pill col-md-12 mt-3" *ngIf="showPhoto">
            <input id="photoFileInput" class="custom-file-input rounded-pill" type="file"
                   accept="image/jpeg, image/png"
                   (change)="fileChangeEvent($event, 'PHOTO', 'ownerPhoto')"
                   required/>
            <label class="custom-file-label  rounded-pill" for="photoFileInput" data-browse="Parcourir">
              {{'forms.vouchers.photo'|translate}}{{imageName? ('forms.vouchers.choosen'|translate) +imageName: '*'}}</label>
          </div>
          <div
            *ngIf="showPhoto && voucherForm.get('photo').invalid && voucherForm.get('photo').touched"
            class="error-message col-md-12 mt-3">
            {{'forms.mandatory'|translate}}
          </div>
        </div>
        <div class="col">
          <mat-form-field *ngIf="isDriverIdRequired" class="p-2 mt-3" style="width: 80%">
            <mat-label>{{'forms.vouchers.typeDriver'|translate}}</mat-label>
            <mat-select (selectionChange)="selectVoucherType($event, 'driver')" formControlName="voucherTypeDriver">
              <mat-option *ngFor="let voucherType of lVoucherTypes" [value]="voucherType.code">
                {{voucherType.label}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="isDriverIdRequired && voucherForm.get('voucherTypeDriver').invalid && voucherForm.get('voucherTypeDriver').touched">
              {{'forms.mandatory'|translate}}
            </mat-error>
          </mat-form-field>
          <div *ngIf="isDriverIdRequired && showIdDriver" class="custom-file overflow-hidden rounded-pill col-md-12 mt-3">
            <input id="driverIdCard" class="custom-file-input rounded-pill" type="file"
                   accept="image/jpeg, image/png, application/pdf"
                   (change)="fileChangeEvent($event, 'ID','driverIdCard')" />
            <label class="custom-file-label rounded-pill" for="driverIdCard" data-browse="Parcourir">
              {{'forms.vouchers.idDriver'|translate}}
              {{driverIdCardName? ('forms.vouchers.choosen'|translate) + driverIdCardName: ' *'}}</label>
          </div>
          <div
            *ngIf="isDriverIdRequired && voucherForm.get('driverIdCard').invalid && voucherForm.get('driverIdCard').touched"
            class="error-message overflow-hidden rounded-pill col-md-12 ">
            {{'forms.mandatory'|translate}}
          </div>
          <div class="custom-file overflow-hidden rounded-pill col-md-12 mt-3"
               *ngIf="isDriverIdRequired && showPassportDriver">
            <input id="driverPassport" class="custom-file-input rounded-pill" type="file"
                   accept="image/jpeg, image/png, application/pdf"
                   (change)="fileChangeEvent($event, 'PASSPORT','driverPassport')"
                   required="{{isDriverIdRequired}}"
            />
            <label class="custom-file-label rounded-pill" for="driverPassport" data-browse="Parcourir">
              {{'forms.vouchers.passport_driver'|translate}}
              {{driverPassportName? ('forms.vouchers.choosen'|translate) + driverPassportName: ' *'}}</label>
          </div>
          <div
            *ngIf="isDriverIdRequired && showPassportDriver && voucherForm.get('driverIdCard').invalid && voucherForm.get('driverIdCard').touched"
            class="error-message overflow-hidden rounded-pill col-md-12 ">
            {{'forms.mandatory'|translate}}
          </div>
          <div class="custom-file overflow-hidden rounded-pill col-md-12 mt-3" *ngIf="showPhotoDriver && isDriverIdRequired">
            <input id="photoFileInputDriver" class="custom-file-input rounded-pill" type="file"
                   accept="image/jpeg, image/png"
                   (change)="fileChangeEvent($event, 'PHOTO', 'driverPhoto')"/>
            <label class="custom-file-label  rounded-pill" for="photoFileInputDriver" data-browse="Parcourir">
              {{'forms.vouchers.photoDriver'|translate}}{{imageDriverName? ('forms.vouchers.choosen'|translate) +imageDriverName: '*'}}</label>
          </div>
          <mat-error
            *ngIf="isDriverIdRequired && showPhotoDriver && voucherForm.get('photoDriver').invalid && voucherForm.get('photoDriver').touched"
            class="error-message col-md-12 mt-3">
            {{'forms.mandatory'|translate}}
          </mat-error>
        </div>
        <div class="col">
          <div class="custom-file overflow-hidden rounded-pill col-md-12 mt-3">
            <input id="vehicleDocumentFile" class="custom-file-input rounded-pill" type="file"
                   accept="image/jpeg, image/png, application/pdf"
                   (change)="fileChangeEvent($event, 'CERTIFICATE', 'document')"
                   required/>
            <label class="custom-file-label rounded-pill" for="vehicleDocumentFile" title="Carte grise"
                   data-browse="Parcourir">
              {{'forms.vouchers.document'|translate}}
              {{vehicleCertificateName? ('forms.vouchers.choosen'|translate) +vehicleCertificateName: ' *'}}</label>
          </div>
          <div
            *ngIf="voucherForm.get('vehicleCertificate').invalid && voucherForm.get('vehicleCertificate').touched"
            class="error-message overflow-hidden rounded-pill col-md-12 ">
            {{'forms.mandatory'|translate}}
          </div>
        </div>
        <div class="col">
          <div class="custom-file overflow-hidden rounded-pill col-md-12 mt-3">
            <input id="vehiclePassFile" class="custom-file-input rounded-pill" type="file"
                   accept="image/jpeg, image/png, application/pdf"
                   (change)="fileChangeEvent($event, 'CERTIFICATE', 'pass')"
                   required/>
            <label class="custom-file-label rounded-pill" for="vehiclePassFile" title="Permis de conduire"
                   data-browse="Parcourir">
              {{'forms.vouchers.pass'|translate}}
              {{vehiclePassName? ('forms.vouchers.choosen'|translate) +vehiclePassName: ' *'}}</label>
          </div>
          <div
            *ngIf="voucherForm.get('vehiclePass').invalid && voucherForm.get('vehiclePass').touched"
            class="error-message overflow-hidden rounded-pill col-md-12 ">
            {{'forms.mandatory'|translate}}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</ng-container>
