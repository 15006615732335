import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Observable} from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class OrganisationTypesService {

  private type = new BehaviorSubject(null);

  constructor() {
  }

  setOrganisationType(type: string) {
    this.type.next(type);
  }


  getOrganisationType(): Observable<string> {
    return this.type.asObservable();
  }

}
