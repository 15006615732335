<nav class="navbar navbar-expand-lg navbar-dark guinee-flag">
  <a class="navbar-brand" href="#" style=" margin-right: 0 !important;padding-left: 0!important;">
    <div class="header-title">
      <img src="assets/images/logo.png" class="d-inline-block align-top" width="60px" height="70px">
      <div style="text-align: center;font-weight: normal !important;margin-left: 3%;font-size: 0.9rem">
        &nbsp; &nbsp; {{'Guinee.title'|translate}}
        <br/> {{'Guinee.minister'|translate}}<br/>
        <span style="font-size: 0.7rem">({{'Guinee.official'|translate}})</span>
      </div>
    </div>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" style="justify-content: flex-end !important;">
    <ul class="navbar-nav" style="width: inherit!important;height: inherit !important;">
      <li class="nav-item">
        <button mat-button class="mat-raised-button bg-transparent text-white" (click)="navigate('consultation')">
          {{'header.consultation'|translate}}
        </button>
      </li>
      <li class="nav-item">
        <button mat-button class="mat-raised-button bg-transparent text-white" (click)="navigate('modification')">
          {{'header.modification'| translate}}
        </button>
      </li>
      <li class="nav-item">
        <button mat-button class="mat-raised-button bg-transparent text-white"  (click)="navigate('contact')">
          <mat-icon style="vertical-align:middle">local_post_office</mat-icon>
        </button>
      </li>
      <li class="nav-item">

        <button mat-button class="mat-raised-button bg-transparent text-white" [matMenuTriggerFor]="menu">
          {{'header.lang'|translate}}
          <mat-icon style="vertical-align:middle">keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="nofocus" (click)="useLanguage('fr')">
            {{'common.language.french' |translate}}
          </button>
          <button mat-menu-item class="nofocus" (click)="useLanguage('en')">
            {{'common.language.english'| translate}}
          </button>
        </mat-menu>
      </li>
    </ul>
  </div>
</nav>
