<div style="margin-top: 3%" *ngIf="!mailValidated">
  <div class="container">
    <mat-card class="container" style="margin-bottom: 2%;">
      <mat-card-header>
        <mat-card-title>
          <div style="vertical-align: middle">
            <mat-icon>person_outline</mat-icon>&nbsp; <strong>{{'forms.modification.title'|translate}}</strong>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="container">
        <form [formGroup]="validationForm">
          <div class="row">
            <mat-form-field class="col col-md-4">
              <input matInput placeholder="{{'forms.infos.email'|translate}}" formControlName="email" maxlength="100"
                     required>
              <mat-error *ngIf="validationForm.get('email').errors && validationForm.get('email').errors.required">
                {{'forms.mandatory'|translate}}
              </mat-error>
              <mat-error *ngIf="validationForm.get('email').errors && validationForm.get('email').errors.email">
                {{'login.wrongFormat'|translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col col-md-3">
              <input matInput placeholder="{{'forms.consultation.recordNumber'|translate}}" maxlength="50"
                     formControlName="recordNumber" required>
              <mat-error
                *ngIf="validationForm.get('recordNumber').errors && validationForm.get('recordNumber').errors.required">
                {{'forms.mandatory'|translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col col-md-4">
              <input matInput placeholder="{{'forms.infos.verificationCode'|translate}}"
                     formControlName="code" required>
              <mat-error *ngIf="validationForm.get('code').errors && validationForm.get('code').errors.required">
                {{'forms.mandatory'|translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row mt-2">
            <div class="col col-md-12">
              <em>
                <span style="color:dimgrey">{{'forms.modification.receivedCodeQuestion'|translate}} ? </span>
                <a [routerLink]="['/modification']" (click)="openResendCode()">
                  {{'forms.modification.receive'|translate}}
                </a>
              </em>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>


<form [formGroup]="modificationForm">
  <mat-horizontal-stepper class="container-stepper bg-transparent" [linear]="true" #stepper *ngIf="mailValidated">
    <mat-step *ngIf="informationsRequired" [completed]="false" [stepControl]="modificationForm.get('personalInfos')">
      <ng-template matStepLabel>{{'forms.steps.personal' |translate}}</ng-template>
      <step-owner-infos [parentForm]="modificationForm" [mode]="mode" [devMode]="devMode"></step-owner-infos>

      <div class="first-stepping container">
        <button class="mat-raised-button submit-button width" (click)="goForward(1)">{{'common.next'|translate}}
        </button>
      </div>
    </mat-step>
    <mat-step *ngIf="informationsRequired" [completed]="false" [stepControl]="modificationForm.get('driverInfos')">
      <ng-template matStepLabel>{{'forms.steps.driver' |translate}}</ng-template>
      <step-driver-infos [parentForm]="modificationForm" [mode]="mode" [devMode]="devMode"></step-driver-infos>
      <div class="stepping container">
        <button class="mat-raised-button back-button width" (click)="goBack()">{{'common.previous'|translate}}</button>
        <button class="mat-raised-button submit-button width" (click)="goForward(2)">{{'common.next'|translate}}
        </button>
      </div>
    </mat-step>
    <mat-step *ngIf="informationsRequired" [completed]="false" [stepControl]="modificationForm.get('vehicleInfos')">
      <ng-template matStepLabel>{{'forms.steps.stay' |translate}}</ng-template>
      <step-vehicle-infos [parentForm]="modificationForm" [mode]="mode" [devMode]="devMode"></step-vehicle-infos>
      <div class="stepping container">
        <button class="mat-raised-button back-button width" (click)="goBack()">{{'common.previous'|translate}}</button>
        <button class="mat-raised-button submit-button width" (click)="goForward(3)">{{'common.next'|translate}}
        </button>
      </div>
    </mat-step>
    <mat-step *ngIf="supportsRequired" [completed]="false" [stepControl]="modificationForm">
      <ng-template matStepLabel>{{'forms.steps.vouchers' |translate}}</ng-template>
      <div class="container">
        <mat-card class="container">
          <mat-card-header>
            <mat-card-title>
              <div style="vertical-align: middle">
                <mat-icon>local_post_office</mat-icon>&nbsp; <strong>{{'forms.steps.vouchers' |translate}}</strong>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div>
              <br/>
              <span>{{'forms.textSelect' |translate}}</span>
              <br/>
            </div>
            <mat-card class="ecart" *ngFor="let additionnalSupport of additionnalSupports">
              <mat-card-content>
                <div class="grid-container">
                  <div class="text-container">
                    <strong class="text-left">{{additionnalSupport.name}}</strong>
                    <span class="file-class"
                          *ngIf="additionnalSupport?.haveValidFile && additionnalSupport?.fileName != null">
                      {{additionnalSupport.fileName}}<span class="remove-class"
                                                           (click)="removeVouchersFormArray(additionnalSupport)">X</span></span>
                  </div>
                  <div class="button-container">
                    <button (click)="openDialog(additionnalSupport)" mat-raised-button color="primary"
                            class="button-right">{{ getButtonName(additionnalSupport) | translate}}
                    </button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="first-stepping container">
        <button [disabled]="validAllDocument()" class="mat-raised-button submit-button width"
                (click)="goForward(4)">{{'common.next'|translate}}
        </button>
      </div>
    </mat-step>
    <mat-step [completed]="false">
      <ng-template matStepLabel>{{'forms.steps.summary' |translate}}</ng-template>
      <step-recap [mode]="mode"></step-recap>
      <div class="stepping container" style="width: 100%">
        <div class="container stepping">
          <button class="mat-raised-button back-button width" style="margin-left: 1%" (click)="goBack()">
            {{'common.previous'|translate}}
          </button>
          <button class="mat-raised-button submit-button width" style="margin-right: 1%"
                  (click)="update()">{{'common.update'|translate}}</button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</form>
<div class="first-stepping container">
  <button class="mat-raised-button submit-button width"
          *ngIf="!mailValidated"
          (click)="getPassRecord()"
          mat-button>
    {{'common.next'|translate}}
  </button>
</div>
