import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  private isDriverIdRequiredObs = new BehaviorSubject(true);
  private isManageOwnerDriver = new BehaviorSubject(true);

  constructor() {
  }

  requireDriverId(require: boolean) {
    this.isDriverIdRequiredObs.next(require);
  }

  requireManageOwnerDriver(require: boolean) {
    this.isManageOwnerDriver.next(require);
  }

  isDriverIdRequired(): Observable<boolean> {
    return this.isDriverIdRequiredObs.asObservable();
  }

  isManageOwnerDriverRequired(): Observable<boolean> {
    return this.isManageOwnerDriver.asObservable();
  }

}
