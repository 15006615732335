<div class="container" style="margin-top: 2%;height: 100%">
  <mat-card *ngIf="!trackingResult">
    <mat-card-header>
      <mat-card-title>
        <div style="vertical-align: middle">
          <mat-icon>photo_filter</mat-icon>&nbsp; <strong>{{'forms.consultation.title'|translate}}</strong>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="container">
      <form [formGroup]="trackRecordForm">
        <div class="row">
          <mat-form-field class="col col-md-4">
            <input matInput placeholder="{{'forms.infos.email'|translate}}" formControlName="email" maxlength="100"
                   required>
            <mat-error *ngIf="trackRecordForm.get('email').errors && trackRecordForm.get('email').errors.required">
              {{'forms.mandatory'| translate}}
            </mat-error>
            <mat-error *ngIf="trackRecordForm.get('email').errors && trackRecordForm.get('email').errors.email">
              {{'login.wrongFormat'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col col-md-3">
            <input matInput placeholder="{{'forms.consultation.recordNumber'|translate}}" maxlength="50"
                   formControlName="recordNumber" required>
            <mat-error
              *ngIf="trackRecordForm.get('recordNumber').errors && trackRecordForm.get('recordNumber').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col col-md-4">
            <input matInput (input)="toUpperCase('registration')" placeholder="{{'forms.vehicle.registration'|translate}}" formControlName="registration" required>
            <mat-error
              *ngIf="trackRecordForm.get('registration').errors && trackRecordForm.get('registration').errors.required">
              {{'forms.mandatory'|translate}}
            </mat-error>
            <mat-error
              *ngIf="trackRecordForm.get('registration').errors && trackRecordForm.get('registration').errors.maxlength">
              {{'forms.errors.maxLength'|translate}} 100 {{'forms.errors.chars'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card style="margin-top: 3%" *ngIf="trackingResult">
    <mat-card-header>
      <mat-card-title>
        <div style="vertical-align: middle">
          <mat-icon>photo_filter</mat-icon>&nbsp; <strong>{{'forms.consultation.status'|translate}}</strong>{{trackRecordForm.get('recordNumber').value}}
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="container">
      <div style="padding: 6%;width: 100%;min-height: 100%!important;" class="componenet-only-in-desktop">
        <div class="row"><br/>
          <div class="col col-md-12">
            <div class="progress">

              <div class="zero bg-warning"
                   *ngIf="created || checking || waiting || processing || shipping || end"></div>
              <div class="zero no-color"
                   *ngIf="!created && !checking && !waiting && !processing && !shipping && !end"></div>

              <div class="one bg-warning" *ngIf="checking || waiting || processing || shipping ||end"></div>
              <div class="one no-color" *ngIf="!checking && !waiting && !processing && !shipping && !end"></div>

              <div class="two bg-warning" *ngIf="waiting || processing || shipping ||end"></div>
              <div class="two no-color" *ngIf="!waiting && !processing && !shipping && !end"></div>

              <div class="three bg-warning" *ngIf="processing || shipping || end"></div>
              <div class="three no-color" *ngIf="!processing && !shipping && !end"></div>

              <div class="four bg-warning" *ngIf="shipping || end"></div>
              <div class="four no-color" *ngIf="!shipping && !end"></div>

              <div class="five bg-warning" *ngIf="end"></div>
              <div class="five no-color" *ngIf="!end"></div>
              <div style="border: 2px solid lightgrey ;"
                   [class]=" 'progress-bar progress-bar-striped  bg-warning ' + (end ? 'five-width': (shipping? 'four-width': (processing? 'three-width': (waiting ? 'two-width':(checking ? 'one-width':'zero-width')))))"></div>
            </div>
          </div>
        </div>

        <div class="row"><br/>
          <div class="col-md-12 mt-5">
            <div class="zero-text">{{'forms.consultation.created'|translate}}</div>
            <div class="one-text ">{{'forms.consultation.validating'|translate}}</div>
            <div class="two-text ">{{'forms.consultation.waiting'|translate}}</div>
            <div class="three-text">{{'forms.consultation.processing'|translate}}</div>
            <div class="four-text">{{'forms.consultation.shipping'|translate}}</div>
            <div class="five-text">{{'forms.consultation.closed'|translate}}</div>
          </div>
        </div>
      </div>
      <div style="padding: 6%;width: 100%;min-height: 100%!important;"  class="componenet-only-in-mobile">
        <div class="progress progress-bar-vertical">
          <div class="zero-vertical bg-warning"
               *ngIf="created || checking || waiting || processing || shipping || end"></div>
          <div class="zero-vertical no-color"
               *ngIf="!created && !checking && !waiting && !processing && !shipping && !end"></div>

          <div class="one-vertical bg-warning" *ngIf="checking || waiting || processing || shipping ||end"></div>
          <div class="one-vertical no-color" *ngIf="!checking && !waiting && !processing && !shipping && !end"></div>

          <div class="two-vertical bg-warning" *ngIf="waiting || processing || shipping ||end"></div>
          <div class="two-vertical no-color" *ngIf="!waiting && !processing && !shipping && !end"></div>

          <div class="three-vertical bg-warning" *ngIf="processing || shipping || end"></div>
          <div class="three-vertical no-color" *ngIf="!processing && !shipping && !end"></div>

          <div class="four-vertical bg-warning" *ngIf="shipping || end"></div>
          <div class="four-vertical no-color" *ngIf="!shipping && !end"></div>

          <div class="five-vertical bg-warning" *ngIf="end"></div>
          <div class="five-vertical no-color" *ngIf="!end"></div>
          <div
            [class]=" 'progress-bar progress-bar-striped  bg-warning ' + (end ? 'five-height': (shipping? 'four-height': (processing? 'three-height': (waiting ? 'two-height':(checking ? 'one-height':'zero-height')))))">
          </div>
          <div class="row"><br/>
            <div>
              <div class="zero-text-vertical">Crée</div>
              <div class="one-text-vertical">En cours de validation</div>
              <div class="two-text-vertical">En attente de mise à jour</div>
              <div class="three-text-vertical">En cours de traitement</div>
              <div class="four-text-vertical">En cours de livraison</div>
              <div class="five-text-vertical">Clôturé</div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="first-stepping">
    <button class="mat-raised-button submit-button width"
            (click)="getStatus()"
            mat-button *ngIf="!trackingResult">
      {{'forms.consultation.search'|translate}}
    </button>
    <button class="mat-raised-button submit-button width"
            (click)="goHome()"
            mat-button *ngIf="trackingResult">
      {{'forms.consultation.backToHome'|translate}}
    </button>
  </div>
</div>


