import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RefInterface} from '../../../../../core/model/ref.interface';
import {LanguageService} from '../../../../../shared/services/language.service';
import * as frOrganisationTypeData from '../../../../../shared/data/fr-constants/companyType.json';
import * as enOrganisationTypeData from '../../../../../shared/data/en-constants/companyType.json';
import * as frGenderListData from '../../../../../shared/data/fr-constants/genderList.json';
import * as enGenderListData from '../../../../../shared/data/en-constants/genderList.json';
import {OrganisationTypesService} from '../../../../../shared/services/organisation-types.service';
import {SubscriptionService} from '../../../subscription.service';
import {CountryInterface} from '../../../../../core/model/country.interface';
import * as frCountryListData from '../../../../../shared/data/fr-constants/countries.json';
import * as enCountryListData from '../../../../../shared/data/en-constants/countries.json';
import {DateValidator} from '../../../../../core/validators/date.validator';
import {FrenchDateAdapter} from '../../../../../core/adapters/french-date.adapter';

@Component({
  selector: 'organisation-infos',
  templateUrl: './organisation-infos.component.html',
  styleUrls: ['./organisation-infos.component.css']
})
export class OrganisationInfosComponent implements OnInit {


  organisationForm: FormGroup;

  @Input() parentFormGroup: FormGroup;
  @Input() modificationAllowed: boolean;


  private _organisationTypes: RefInterface[];
  private _genderList: RefInterface[];
  private lCountryList: CountryInterface[];

  today = new Date();

  phonePattern = '^[0-9]{3,15}$|\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|' +
    '6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{1,14}$';

  constructor(private formBuilder: FormBuilder,
              private organisationTypesService: OrganisationTypesService,
              private subscriptionService: SubscriptionService,
              private dateService: FrenchDateAdapter,
              private languageService: LanguageService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.organisationForm = this.formBuilder.group({
      lastName: [null, [Validators.required, Validators.maxLength(100)]],
      firstName: [null, [Validators.required, Validators.maxLength(100)]],
      gender: [null, Validators.required],
      jobTitle: [null, [Validators.required, Validators.maxLength(100)]],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      email: [null, Validators.maxLength(100)],
      phone: [null, [Validators.maxLength(15), Validators.pattern(this.phonePattern)]],
      address: [null],
      siren: [null, [Validators.maxLength(50)]],
      type: [null, Validators.required],
      birthDate: [null, [Validators.required, DateValidator.adult]],
      birthCountry: [null, Validators.required],
      birthCity: [null, Validators.required],
    });
    this.parentFormGroup.setControl('organisationInfos', this.organisationForm);

    this.organisationForm.get('type').valueChanges.subscribe(type => {
      this.organisationTypesService.setOrganisationType(type.code);
    });

    this.languageService.getCurrentLanguage().subscribe(lang => {
      const selectedOrganisationType = this.organisationForm.get('type').value;
      const selectedGender = this.organisationForm.get('gender').value;
      switch (lang) {
        case 'fr':
          this._organisationTypes = (frOrganisationTypeData as any).default;
          this._genderList = (frGenderListData as any).default;
          this.lCountryList = (frCountryListData as any).default;
          break;
        case 'en':
          this._organisationTypes = (enOrganisationTypeData as any).default;
          this._genderList = (enGenderListData as any).default;
          this.lCountryList = (enCountryListData as any).default;
          break;
      }
      if (selectedOrganisationType) {
        this.organisationForm.get('type').setValue(this._organisationTypes.filter(item => item.code === selectedOrganisationType.code)[0]);
      }
      if (selectedGender) {
        this.organisationForm.get('gender').setValue(this._genderList.filter(item => item.code === selectedGender.code)[0]);
      }
    });

    this.subscriptionService.getModification().subscribe( modification => {
      if (modification && modification.applicant && modification.applicant.company) {
        this.organisationForm.get('lastName').setValue(modification.applicant.lastName);
        this.organisationForm.get('firstName').setValue(modification.applicant.firstName);
        this.organisationForm.get('gender').setValue(this._genderList.filter(item => item.code === modification.applicant.gender)[0]);
        this.organisationForm.get('jobTitle').setValue(modification.applicant.jobTitle);
        this.organisationForm.get('name').setValue(modification.applicant.company.name);
        this.organisationForm.get('email').setValue(modification.applicant.company.email);
        this.organisationForm.get('phone').setValue(modification.applicant.company.phone);
        this.organisationForm.get('siren').setValue(modification.applicant.company.siren);
        this.organisationForm.get('type').setValue(this._organisationTypes.filter(item =>
          item.code === modification.applicant.company.type)[0]);
        this.organisationForm.get('birthDate').setValue(this.dateService.parse(modification.applicant.birthInfo.day));
        this.organisationForm.get('birthCountry').setValue(
          this.lCountryList.filter(item => item.code === modification.applicant.birthInfo.country)[0]);
        this.organisationForm.get('birthCity').setValue(modification.applicant.birthInfo.city);
        this.parentFormGroup.get('personalInfos').get('address').get('streetName')
          .setValue(modification.applicant.company.address.streetName);
        this.parentFormGroup.get('personalInfos').get('address').get('town').setValue(modification.applicant.company.address.city);
        this.parentFormGroup.get('personalInfos').get('address').get('additional')
          .setValue(modification.applicant.company.address.additional);
        this.parentFormGroup.get('personalInfos').get('address').get('state').setValue(modification.applicant.company.address.state);
        this.parentFormGroup.get('personalInfos').get('address').get('country').setValue(this.lCountryList.filter(item =>
          item.code === modification.applicant.company.address.country)[0]);

      }
    });
  }

  get genderList(): RefInterface[] {
    return this._genderList;
  }

  get organisationTypes(): RefInterface[] {
    return this._organisationTypes;
  }

  toUpperCase(key: string) {
    const value = this.organisationForm.get(key).value;
    this.organisationForm.get(key).setValue(value.toUpperCase());
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

}
