import {Component, isDevMode, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PassService} from '../../shared/services/pass.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogConfirmComponent} from '../../shared/dialog-confirm/dialog-confirm.component';
import {MatDialog} from '@angular/material';
import {SpinnerOverlayService} from '../../shared/services/spinner-overlay.service';

@Component({
  selector: 'track-record',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.css']
})
export class ConsultationComponent implements OnInit {

  trackRecordForm: FormGroup;
  devMode: boolean;

  constructor(private formBuilder: FormBuilder,
              private passService: PassService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private spinnerService: SpinnerOverlayService
  ) {
    this.activatedRoute.url.subscribe((fragment) => {
      if (fragment.pop().path === 'consultation') {
        this.created = false;
        this.checking = false;
        this.waiting = false;
        this.processing = false;
        this.shipping = false;
        this.end = false;
      }
    });
  }

  created = false;
  checking = false;
  waiting = false;
  processing = false;
  shipping = false;
  end = false;
  trackingResult = false;

  ngOnInit() {
    this.devMode = isDevMode();
    this.trackRecordForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email, Validators.maxLength(80)]],
      recordNumber: [null, [Validators.required]],
      registration: [null, [Validators.required, Validators.maxLength(100)]]
    });
  }

  goHome() {
    window.location.href = '/';
  }

  getStatus() {

    if (this.trackRecordForm.valid) {
      const reference = this.trackRecordForm.get('recordNumber').value;
      const email = this.trackRecordForm.get('email').value;
      const registration = this.trackRecordForm.get('registration').value;

      this.spinnerService.show();
      this.passService.getPassRecord(reference, email, registration).subscribe((result: any) => {
        if (result && result.status && result.status === 'OK' && result.data.status) {
          const state = result.data.status;
          switch (state) {
            case 'CREATED':
              this.created = true;
              break;
            case 'PENDING_INFORMATION':
            case 'PENDING_SUPPORT':
              this.waiting = false;
              this.checking = true;
              break;
            case 'CHECKING':
              this.checking = true;
              break;
            case 'PROCESSING':
              this.processing = true;
              break;
            case 'SHIPPING':
              this.shipping = true;
              break;
            case 'END':
              this.end = true;
              break;
          }
          this.trackingResult = true;
          this.spinnerService.hide();
        } else {
          this.trackingResult = false;
          this.spinnerService.hide();
          this.handleError(result.error);
        }
      });
    } else {
      this.trackRecordForm.markAllAsTouched();
    }
  }


  handleError(error) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '500px',
      panelClass: 'my-dialog',
      data: {
        message: error,
      }
    });
  }

  toUpperCase(key: string) {
    const value = this.trackRecordForm.get(key).value;
    this.trackRecordForm.get(key).setValue(value.toUpperCase());
  }

}
