import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'dialog-cropper',
  templateUrl: './dialog-cropper.component.html',
  styleUrls: ['./dialog-cropper.component.css']
})
export class DialogCropperComponent implements OnInit {


  croppedImage: any = '';
  public imageSource;
  show = false;

  constructor(public dialogRef: MatDialogRef<DialogCropperComponent>,
              @Inject(MAT_DIALOG_DATA) public imageFile) {
    const reader = new FileReader();
    reader.readAsDataURL(this.imageFile);
    reader.onload = (event: any) => {
      this.imageSource = event.target.result;
    };
  }

  ngOnInit() {
    this.show1();
  }

  closeDialog(reset): void {
    this.dialogRef.close({reset, data: this.croppedImage});
  }

  show1() {
    setInterval(a => {
      // this.show1 = !this.show1;
      this.show = true;
    }, 500, []);
  }


  croppedImageHandler(croppedImageEvent: string) {
    this.croppedImage = croppedImageEvent;
  }

}
