<h1 mat-dialog-title>{{'forms.txtAjoutVoucher'| translate}} <strong>{{ data.addiSupport.name}}</strong></h1>
<form [formGroup]="modalForm" (ngSubmit)="closeDialog()">
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="selectOption">{{'forms.selectOp'| translate}}</label>
      <select
        [ngClass]="{'is-invalid': modalForm.get('agent').invalid && (modalForm.get('agent').dirty || modalForm.get('agent').touched)}"
        class="form-control" id="selectOption" formControlName="agent" required>
        <option *ngFor="let agent of agents" [ngValue]="agent">
          {{agent.firstName}} {{agent.lastName}}
        </option>
      </select>
      <div *ngIf="modalForm.get('agent').invalid && (modalForm.get('agent').dirty || modalForm.get('agent').touched)">
        <span class="error-message">{{'forms.mandatory'| translate}}</span>
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="fileInput">{{'forms.selectFile'| translate}}</label>
      <div class="custom-file">
        <input type="file"
               [ngClass]="{'is-invalid': modalForm.get('file').invalid && (modalForm.get('file').dirty || modalForm.get('file').touched)}"
               class="custom-file-input"
               accept="image/*,application/pdf"
               id="fileInput"
               (change)="fileChangeEvent($event)"
               formControlName="file"
               required>
        <label class="custom-file-label" for="fileInput">{{ fileName }}</label>
      </div>
      <div *ngIf="modalForm.get('file').invalid && (modalForm.get('file').dirty || modalForm.get('file').touched)">
        <span class="error-message">{{'forms.mandatory'| translate}}</span>
      </div>
    </div>
  </div>
  <div class="button-container">
    <button mat-raised-button mat-dialog-close>{{'forms.fermer'| translate}}</button>
    <button type="submit" mat-raised-button class="valid-button">{{'forms.valider'| translate}}</button>
  </div>
</form>
